import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ROUTES, HttpResponse } from 'src/app/shared';
import { CampaignListSchema, CampaignSchema } from '../models/activity.interface';
import { LikedUserSchema } from '../../liked-people/interfaces/liked-people.interface';
import { Lightbox } from 'ngx-lightbox';

@Injectable({
  providedIn: 'root',
})
export class ManageActivityService {
  constructor(private http: HttpClient, private _lightbox: Lightbox) { }

  public getAllCampaings(page: number, limit: number): Observable<HttpResponse<{ data: CampaignSchema[]; count: 0 }>> {
    let params = new HttpParams();
    params = params.append('page', page || 1);
    params = params.append('limit', limit || 10);
    return this.http.get<HttpResponse<{ data: CampaignSchema[]; count: 0 }>>(API_ROUTES.CAMPAIGNS.GET, { params: params });
  }

  public getCampaignById(id: string): Observable<HttpResponse<CampaignListSchema>> {
    return this.http.get<HttpResponse<CampaignListSchema>>(`${API_ROUTES.CAMPAIGNS.GET_BY_ID}/${id}`);
  }

  public getFollowed(id: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.get<HttpResponse<{ code: string }>>(`${API_ROUTES.CAMPAIGNS.GET_BY_ID}/${id}/follow/is_followed`);
  }

  public httpAddCampaign(requestPayload: any): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(API_ROUTES.CAMPAIGNS.POST, requestPayload);
  }

  public updateCampaign(id: any, requestPayload: any): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(`${API_ROUTES.CAMPAIGNS.GET_BY_ID}/${id}`, requestPayload);
  }

  public deleteCampaign(id: any): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(`${API_ROUTES.CAMPAIGNS.GET}/${id}`);
  }

  public postReportCampaignById(id: string, data: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(`${API_ROUTES.CAMPAIGNS.POST}/${id}/spamReport`, data);
  }

  updateCampaignStatus(id: any, payloadBody: { status: string, reason: string }): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(`${API_ROUTES.CAMPAIGNS.GET_BY_ID}/isPublished/${id}`, payloadBody);
  }

  public httpFollowProfile(isFollowed: boolean | string, profileId: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(
      `${API_ROUTES.CAMPAIGNS.ALL}/${profileId}/follow/is_followed`, { is_followed: isFollowed ? 'true' : 'false' },
    );
  }

  public httpLikeDislikePeople(payload: LikedUserSchema, profileId: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(`${API_ROUTES.CAMPAIGNS.POST}/${profileId}/is_liked_disliked`, payload);
  }

  public zoomImage(_album: any, i: number) { return this._lightbox.open(_album, i); }

  public httpComments(id: string, self?: string): Observable<HttpResponse<any>> {
    const selfCheck = self ? `?self=${self}` : '';
    return this.http.get<HttpResponse<any>>(`${API_ROUTES.CAMPAIGNS.POST}/${id}/comments${selfCheck}`);
  }

  public httpPostComment(id: string, payload: FormData): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(`${API_ROUTES.CAMPAIGNS.ALL}/${id}/comments`,payload);
  }

  public httpPostSubComment(id: string, commentId: string, payload: FormData): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(`${API_ROUTES.CAMPAIGNS.ALL}/${id}/comments/${commentId}/reply`,payload);
  }

  public httpProjectSubComments(id: string, commentId: string, self?: string): Observable<HttpResponse<any>> {
    const selfCheck = self ? `?self=${self}` : '';
    return this.http.get<HttpResponse<any>>(`${API_ROUTES.CAMPAIGNS.POST}/${id}/comments/${commentId}/replies${selfCheck}`);
  }

  public httpDeleteComment(id: string, commentId: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(`${API_ROUTES.CAMPAIGNS.ALL}/${id}/comments/${commentId}`);
  }

  public httpSubDeleteComment(id: string, commentId: string, replyId: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(`${API_ROUTES.CAMPAIGNS.ALL}/${id}/comments/${commentId}/replies/${replyId}`);
  }

  public httpLikeDislikeComment(projectId: string, commentId: string, payload: any
    ): Observable<HttpResponse<{ code: string }>> {
      return this.http.post<HttpResponse<{ code: string }>>(
        `${API_ROUTES.CAMPAIGNS.ALL}/${projectId}/comments/${commentId}/like-dislike`, payload);
    }
  
  public httpLikeDislikeSubComment(projectId: string, commentId: string, replyId: string, payload: any
    ): Observable<HttpResponse<{ code: string }>> {
      return this.http.post<HttpResponse<{ code: string }>>(
        `${API_ROUTES.CAMPAIGNS.ALL}/${projectId}/comments/${commentId}/replies/${replyId}/like-dislike`,payload);
    }

  public httpCommentStatus(projectId: string, commentId: string, payload: any
      ): Observable<HttpResponse<{ code: string }>> {
        return this.http.patch<HttpResponse<{ code: string }>>(
        `${API_ROUTES.CAMPAIGNS.ALL}/${projectId}/comments/${commentId}/status`, payload);
      }
  
  public httpReplyStatus(projectId: string, commentId: string, replyId: string, payload: any
      ): Observable<HttpResponse<{ code: string }>> {
        return this.http.patch<HttpResponse<{ code: string }>>(
        `${API_ROUTES.CAMPAIGNS.ALL}/${projectId}/comments/${commentId}/replies/${replyId}/status`, payload);
      }
  public getCampaignProject(id: string): Observable<HttpResponse<any>> {
    const url = API_ROUTES.PROJECTS.PROJECTS_UNDER_CAMPAIGN;
    return this.http.get<HttpResponse<any>>(`${url}/${id}`);
  }
}
