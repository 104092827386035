import { ChangeDetectorRef, Component } from '@angular/core';
import { EventBusService, TokenStorageService } from './core/services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Events } from './core/enums';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { CoreService } from './shared';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'yap';

  public isLoaderVisible: boolean = false;
  private subscription: Subscription = new Subscription();
  public isMobileCmsScreen: boolean = false;

  constructor(
    private eventBusService: EventBusService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private tokenStorageService: TokenStorageService,
    private authService: SocialAuthService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.eventBusService.on(Events.LOADING_START, () => {
        this.loaderValue = true;
      }),
    );

    this.subscription.add(
      this.eventBusService.on(Events.LOADING_END, () => {
        this.loaderValue = false;
      }),
    );

    this.subscription.add(
      this.eventBusService.on(Events.SIGNOUT, () => {
        this.signOut();
      }),
    );
  }

  private set loaderValue(event: boolean) {
    this.isLoaderVisible = event || false;
    this.cdr.detectChanges();
  }

  public signOut() {
    const loginType = this.tokenStorageService.loginType;
    if (loginType && loginType == 'SSO') {
      this.authService.signOut();
    }
    this.tokenStorageService.clearTokenSession();
    this.router.navigate(['/landing-page']);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
