import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/feature/components/login/login.component';
import { Subscription } from 'rxjs';
import { SignupComponent } from 'src/app/feature/components/signup/signup.component';
import { ForgotPasswordComponent } from 'src/app/feature/components/forgot-password/forgot-password.component';
import { EventBusService, TokenStorageService } from 'src/app/core/services';
import { EmitEvent } from 'src/app/core/helpers';
import { Events } from 'src/app/core/enums';
import { environment } from 'src/environment/environment';
import { PaginationConfig, ProfileCountSchema, ProfileSchema } from '../../interfaces';
import { CommonService } from '../../services/common.service';
import { CustomMessageService, HeaderService, MainService } from '../../services';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageDropdownComponent } from '../language-dropdown/language-dropdown.component';
import { GuidedTour, GuidedTourModule, GuidedTourService, Orientation, ProgressIndicatorLocation } from 'ngx-guided-tour';
import { LimitToPipe } from '../../pipes';
import { WinPointComponent } from '../../Modals/win-point/win-point.component';
import { PointSchema } from 'src/app/feature/modules/post-auth-pages/modules/manage-profile/interface/leaderboard.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbModule, RouterModule, FormsModule, TranslateModule, LanguageDropdownComponent, GuidedTourModule, LimitToPipe],
})
export class HeaderComponent implements OnInit, AfterContentInit, OnDestroy {
  private imageUrl: string = environment.imageUrl;
  public userLoggedIn: boolean = false;
  private subscription: Subscription = new Subscription();
  public profileName!: string;
  public profileImage!: string;
  public scrolled: boolean = false;
  public notifyList: any;
  public notifyCount: any;
  public selectedLanguageCode: string = 'en'
  public filter = {
    sortBy: '',
    orderBy: 'ASC'
  };
  public paginationConfig: PaginationConfig = {
    totalItems: 0,
    itemsPerPage: 50,
    currentPage: 1,
  };
  public pointData: any = [];
  public pointId: string = '';
  public total_point: number = 0
  public pointFlag: any;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 15;
  }

  @ViewChild('tourElement') tourElement!: ElementRef;

  private TOUR: GuidedTour = {
    tourId: 'purchases-tour',
    useOrb: false,
    skipCallback: () => localStorage.setItem('guide','1'),
    completeCallback: () => localStorage.setItem('guide','1'),
    steps: [
      {
        title: 'Toolbox',
        selector: '#toolbox-link',
        content: 'Resources for your campaigning efforts',
        orientation: Orientation.Bottom,
        useHighlightPadding: true,
        highlightPadding: 8
      },
      {
        title: 'Explore',
        selector: '#explore-link',
        content: 'Participate in on-going or upcoming opportunities',
        orientation: Orientation.Bottom,
        useHighlightPadding: true,
        highlightPadding: 8
      },
      {
        title: 'Community',
        selector: '#community-link',
        content: 'Connect with peers across the region',
        orientation: Orientation.Bottom,
        useHighlightPadding: true,
        highlightPadding: 8
      },
      {
        title: 'News',
        selector: '#news-link',
        content: 'Browse through the latest news and announcements',
        orientation: Orientation.Bottom,
        useHighlightPadding: true,
        highlightPadding: 8
      },
      {
        title: 'About Us',
        selector: '#about-link',
        content: 'Find out more about the platform',
        orientation: Orientation.Bottom,
        useHighlightPadding: true,
        highlightPadding: 8
      }
    ],
  };

   private loginTour: any = {
    title: 'Login',
    selector: '#login-link',
    content: 'Click here to Login',
    orientation: Orientation.Bottom,
    useHighlightPadding: true,
    highlightPadding: 8
  }

  private addAction: any = {
      title: 'Action',
      selector: '#dropdownMenuButton',
      content: 'Create a new item for others to explore',
      orientation: Orientation.Bottom,
      useHighlightPadding: true,
      highlightPadding: 8
    }

    public showActionTour: boolean = false;
    private ACTION_TOUR: GuidedTour = {
      tourId: 'action-tour',
      useOrb: false,
      skipCallback: () => { localStorage.setItem('loginGuide','1'); this.showActionTour = false},
      completeCallback: () => { 
        localStorage.setItem('loginGuide','1'); 
        this.showActionTour = false
        this.getCompleteGuidePoints();
      },
      steps: [
        {
          title: 'Create Campaign',
          selector: '#createCamp',
          content: 'Click here to create a campaign',
          orientation: Orientation.Left,
        },
        {
          title: 'Create Project',
          selector: '#createProject',
          content: 'Click here to create a project',
          orientation: Orientation.Left,
        },
        {
          title: 'Create Article',
          selector: '#createArticle',
          content: 'Click here to create a article',
          orientation: Orientation.Left,
        },
        {
          title: 'Create Community',
          selector: '#createCommunity',
          content: 'Click here to create a community',
          orientation: Orientation.Left,
        }
      ],
    };
  

  constructor(
    private scroller: ViewportScroller,
    private modalService: NgbModal,
    private tokenStorageService: TokenStorageService,
    private eventBusService: EventBusService,
    private commonService: CommonService,
    private mainService: MainService,
    private headerService: HeaderService,
    private router: Router,
    private toasterService: CustomMessageService,
    private guidedTourService: GuidedTourService,
    private cdref: ChangeDetectorRef,
  ) { }

  ngAfterContentInit(): void {
      this.startTour();
      this.cdref.detectChanges();
  }
  
  public startTour(): void {
    if( !localStorage.getItem('guide')){
      this.toggleField();
      setTimeout(() => {
        this.guidedTourService.startTour(this.TOUR);
      }, 400);
    }
  }
  
  actionTour(){
    if( !localStorage.getItem('loginGuide')){
      this.guidedTourService.startTour(this.ACTION_TOUR);
      this.showActionTour = true;
    }
  }

  footerGuideClick(){
    this.commonService.userGuideClickedEvent.subscribe((data: string) => {
      if(data){
        localStorage.setItem('guide','')
        localStorage.setItem('loginGuide','')
        // setTimeout(() => {
          this.startTour();
          this.cdref.detectChanges();
        // }, 300);
      }
    });
  }

  ngOnInit() {
    if (this.tokenStorageService.getTokenSession) {
      this.TOUR.steps.push(this.addAction);
      this.tokenStorageService.isLoggedIn$.next(true);
      this.getNotifyList();
    } else {
      this.TOUR.steps.push(this.loginTour);
      this.userLoggedIn = false;
    }
    this.footerGuideClick();
    this.subscription.add(
      this.headerService.renderModal.subscribe((res) => {
        if (res) {
          switch (res) {
            case 'LOGIN': {
              this.renderLoginModal();
              break;
            }
            case 'SIGNUP': {
              this.renderSignupModal();
              break;
            }
          }
        }
      }),
    );

    this.subscription.add(
      this.tokenStorageService.isLoggedIn$.subscribe((res) => {
        if (res) {
          this.userLoggedIn = true;
          const response = this.mainService.getUserDetail;
          if (response && response.detail) {
            this.createUserProfile(response.detail);
            this.pointFlag = this.mainService.getUserDetail?.detail?.profile?.points_popup;
            this.getNotifyList();
            this.getPointData();
            this.badgeUpdatedData();
            // this.getPointCount();
          } else {
            this.commonService.profileDetail().subscribe();
          }
        } else {
          this.userLoggedIn = false;
        }
      }),
    );

    this.subscription.add(
      this.commonService.profileData$.subscribe((res) => {
        if (res) {
          const data = res as ProfileSchema;
          this.createUserProfile(data);
          this.getProfileCount(data.profile.user_id);
          // this.getPointData();
        }
      }),
    );
  }

  updateUserProfile(requestBody: any) {
    this.subscription.add(this.commonService.patchUpdateUserUUI(this.mainService.getUserDetail.detail.id, requestBody).subscribe({
      next: ({ data }) => { }
    }));
  }

  getProfileCount(userId: string) {
    this.commonService.getProfileCount(userId).subscribe((res) => {
      const data = res as ProfileCountSchema;
    })
  }

  createUserProfile(data: ProfileSchema) {
    this.profileName =
      data.profile && data.profile.name ? data.profile.name : '';

    if (data) {
      this.profileImage = data && data?.profile && data?.profile?.profile_url ? `${this.imageUrl}${data.profile.profile_url}` : '';
    }
    if (data && localStorage.getItem("fireBaseUserId") && (!data.profile.uid || data.profile.uid === '')) {
      data.uid = localStorage.getItem("fireBaseUserId");
      this.updateUserProfile(data);
    }
  }

  goDown1() {
    this.scroller.scrollToAnchor('targetRed');
  }

  public renderLoginModal() {
    const modalRef = this.modalService.open(LoginComponent, {
      backdrop: true,
      size: 'md', 
    });

    this.subscription.add(
      modalRef.closed.subscribe((res) => {
        if (res && res == 'SIGNUP') {
          this.renderSignupModal();
        }

        if (res && res == 'FORGOT_PASSWORD') {
          this.renderForgotPwdModal();
        }
        if (res && res == 'LOGIN_TRUE') {
          this.getPointData();
          this.getNotifyList();
          // this.getPointCount();
        }
      }),
    );
  }

  public renderSignupModal() {
    const modalRef = this.modalService.open(SignupComponent, {
      backdrop: true,
      size: 'lg',
    });

    this.subscription.add(
      modalRef.closed.subscribe((res) => {
        if (res && res == 'LOGIN') {
          this.renderLoginModal();
        }
      }),
    );
  }

  public renderForgotPwdModal() {
    const modalRef = this.modalService.open(ForgotPasswordComponent, {
      backdrop: true,
      size: 'lg',
    });

    this.subscription.add(
      modalRef.closed.subscribe((res) => {
        if (res && res == 'LOGIN') {
          this.renderLoginModal();
        }
      }),
    );
  }

  public signout() {
    this.eventBusService.emit(new EmitEvent(Events.SIGNOUT));
    localStorage.setItem('selectedLang',this.selectedLanguageCode);
    this.toasterService.show('User logged out successfully', 'success');
  }

  getNotifyList() {
    this.subscription.add(
      this.commonService.httpGetNotificationList(this.paginationConfig.itemsPerPage, this.paginationConfig.currentPage, this.filter.orderBy).subscribe({
        next: ({ data }) => {
          this.notifyList = data && data.data ? data.data : [];
          this.notifyCount = data?.count
        },
      }),
    );
  }

  readNotify(item: any) {
    this.subscription.add(
      this.commonService.httpUpdateNotifyStatus(item?.id).subscribe({
        next: ({ data }) => {
          if (data && data.code) {
            if (item?.type === "announcement") {
              this.router.navigate(['/post-auth/announcements/detail/', item?.content_id])
            }else{
              this.router.navigate(['/post-auth/notification/detail/', item?.id]);
            }
          }
        },
      }),
    );
  }

  public deleteNotify(notifyId: string) {
    this.subscription.add(
      this.commonService.deleteNotify(notifyId).subscribe({
        next: ({ data }) => {
          if (data && data.code) {
            this.toasterService.showHttpMessages(data.code, 'success');
            this.getNotifyList();
          }
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  classToggled = false;

  public toggleField() {
    this.classToggled = !this.classToggled;
  }

  getLanguageCode(code: string){
    this.selectedLanguageCode = code
  }

  public getPointData() {
    if(this.pointFlag){
    this.subscription.add(
      this.commonService.httpGetPoints().subscribe({
        next: ({ data }) => {
          if(data){
            this.pointData = data ? data : ''
            this.pointId = data && data?.id ? data.id : '';
            if(this.pointData){
              this.winPointModal(this.pointData);
            }
          }
        },
      }),
    );
    }
  }
  private winPointModal(pointData: PointSchema){
    if(pointData){
      let modalRef = this.modalService.open(WinPointComponent, { backdrop: true, size: 'lg' });
      modalRef.componentInstance.patchData(pointData);
    }
  }
  public badgeUpdatedData() {
    this.subscription.add(
      this.commonService.httpGetUpdatedBadge().subscribe({
        next: ({ data }) => {
        },
      }),
    );
  }

  public getCompleteGuidePoints() {
    const body = {
      content_id: 'db5f31b7-a9b6-41af-9239-4a727bba6c10'
    }
    if(this.userLoggedIn){
      this.subscription.add(
        this.commonService.httpGetFooterActivtyPoints(body).subscribe({
          next: ({ data }) => {
            if(data){
              this.getPointData();
            }
          },
        }),
      );
    }
  }

  getPointCount() {
    this.subscription.add(
      this.commonService.httpGetTotalPoint().subscribe(({ data }) => {
        this.total_point = data && data?.totalPoints ? data?.totalPoints : 0;
      }),
    );
  }
}