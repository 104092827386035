import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'censor',
  standalone: true
})
export class CensorPipe implements PipeTransform {

  transform(value: string, restrictedWords?: any): string {
    const words = value.split(/\s+/);
    const arrayOfWords = restrictedWords.map((obj:any) => obj.word);

    arrayOfWords.forEach((inappropriateWord:string) => {
      const regex = new RegExp(`\\b${inappropriateWord}\\b`, 'gi');

      words.forEach((word, index) => {
        if (word.toLowerCase() === inappropriateWord.toLowerCase()) {
          words[index] = this.censorWord(word);
        } else {
          words[index] = word.replace(regex, this.censorWord);
        }
      });
    });

    return words.join(' ');
  }

  private censorWord(word: string): string {
    if (word.length <= 2) {
      return word;
    }

    const firstChar = word[0];
    const lastChar = word[word.length - 1];
    const middleChars = '*'.repeat(word.length - 2);

    return `${firstChar}${middleChars}${lastChar}`;
  }

}
