import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-verification-email',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './verification-email.component.html',
  styleUrls: ['./verification-email.component.scss']
})
export class VerificationEmailComponent {
  constructor( private activemodal: NgbActiveModal){}
  
  close(){ this.activemodal.close('LOG_IN');}
}
