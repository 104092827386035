import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ROUTES } from '../routes';
import { HttpResponse, objectType } from '../interfaces';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExploreService {
  constructor(private http: HttpClient) { }

  public _allCampaigns(appliedFilters: objectType = {}, page: number, limit: number): Observable<HttpResponse<{ count: number; data: Array<objectType> }>> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('limit', limit);
    if (appliedFilters && Object.keys(appliedFilters).length)
      for (let key in appliedFilters) { appliedFilters[key] ? (params = params.append(key, appliedFilters[key])) : ''; }
    return this.http.get<HttpResponse<{ count: number; data: Array<objectType> }>>(API_ROUTES.EXPLORE.CAMPAIGNS, { params: params });
  }

  public _allProjects(appliedFilters: objectType = {}, page: number, limit: number): Observable<HttpResponse<{ count: number; data: Array<objectType> }>> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('limit', limit);
    if (appliedFilters && Object.keys(appliedFilters).length)
      for (let key in appliedFilters) { appliedFilters[key] ? (params = params.append(key, appliedFilters[key])) : ''; }
    return this.http.get<HttpResponse<{ count: number; data: Array<objectType> }>>(API_ROUTES.EXPLORE.PROJECTS, { params: params });
  }

  public _allArticles(appliedFilters: objectType = {}, page: number, limit: number): Observable<HttpResponse<{ count: number; data: Array<objectType> }>> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('limit', limit);
    if (appliedFilters && Object.keys(appliedFilters).length)
      for (let key in appliedFilters) { appliedFilters[key] ? (params = params.append(key, appliedFilters[key])) : ''; }
    return this.http.get<HttpResponse<{ count: number; data: Array<objectType> }>>(API_ROUTES.EXPLORE.ARTICLES, { params: params });
  }

  public getActivityDetail(id: string, flag: string): Observable<HttpResponse<any>> {
    const url = flag == 'campaigns' ? API_ROUTES.EXPLORE.CAMPAIGN_DETAIL : flag == 'projects' ? API_ROUTES.EXPLORE.PROJECT_DETAIL : flag == 'articles' ? API_ROUTES.EXPLORE.ARTICLE_DETAIL : '';
    return this.http.get<HttpResponse<any>>(`${url}/${id}`).pipe(
      map((res) => {
        if (res && res.data && res.data.data && res.data.data[0]) { res.data.data = res.data.data[0]; } return res;
      }),
    );
  }

  public reportActivity(id: string, data: any, flag: string): Observable<HttpResponse<{ code: string }>> {
    const url = flag == 'campaigns' ? API_ROUTES.CAMPAIGNS.POST : flag == 'projects' ? API_ROUTES.PROJECTS.POST : flag == 'articles' ? API_ROUTES.ARTICLES.POST : '';
    return this.http.post<HttpResponse<{ code: string }>>(`${url}/${id}/spamReport`, data);
  }

  public followActivity(isFollowed: boolean | string, profileId: string, flag: string): Observable<HttpResponse<{ code: string }>> {
    const url = flag == 'campaigns' ? API_ROUTES.CAMPAIGNS.POST : flag == 'projects' ? API_ROUTES.PROJECTS.POST : flag == 'articles' ? API_ROUTES.ARTICLES.POST : '';
    return this.http.post<HttpResponse<{ code: string }>>(
      `${url}/${profileId}/follow/is_followed`, { is_followed: isFollowed ? 'true' : 'false' },
    );
  }

  public activityFollowData(id: string, flag: string): Observable<HttpResponse<{ code: string }>> {
    const url = flag == 'campaigns' ? API_ROUTES.CAMPAIGNS.GET_BY_ID : flag == 'projects' ? API_ROUTES.PROJECTS.GET : flag == 'articles' ? API_ROUTES.ARTICLES.GET : '';
    return this.http.get<HttpResponse<{ code: string }>>(`${url}/${id}/follow/is_followed`);
  }

  public likeDislikeActivity(payload: any, profileId: string, flag: string): Observable<HttpResponse<{ code: string }>> {
    const url = flag == 'campaigns' ? API_ROUTES.CAMPAIGNS.POST : flag == 'projects' ? API_ROUTES.PROJECTS.POST : flag == 'articles' ? API_ROUTES.ARTICLES.POST : '';
    return this.http.post<HttpResponse<{ code: string }>>(`${url}/${profileId}/is_liked_disliked`, payload);
  }

  public postProjectsContribution(payload: any, projectID: string): Observable<HttpResponse<{ code: string }>> {
    const url = API_ROUTES.PROJECTS.POST_CONTRIBUTION;
    return this.http.post<HttpResponse<{ code: string }>>(`${url}/${projectID}`, payload);
  }
  public getProjectContribution(id: string, status: string, appliedFilters: any = {}): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('project_id', id);
    params = params.append('status', status);
    if (appliedFilters && Object.keys(appliedFilters).length)
      for (let key in appliedFilters) { appliedFilters[key] ? (params = params.append(key, appliedFilters[key])) : ''; }
    return this.http.get<HttpResponse<{ count: number; data: Array<any> }>>(API_ROUTES.PROJECTS.CONTRIBUTIONBYPROJECT, { params: params });

  }

  public getCampaignProject(id: string, filter: any = {}): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    if (filter && Object.keys(filter).length)
      for (let key in filter) { filter[key] ? (params = params.append(key, filter[key])) : ''; }
    const url = API_ROUTES.PROJECTS.PROJECTS_UNDER_CAMPAIGN;
    return this.http.get<HttpResponse<any>>(`${url}/${id}`, { params: params });
  }


  public getProjectContributionById(projectId: string, id: string): Observable<HttpResponse<any>> {
    const url = API_ROUTES.PROJECTS.CONTRIBUTIONBYPROJECT + '?contribution_id=' + id;
    return this.http.get<HttpResponse<any>>(`${url}`);
  }

  public contributionLikeDislikeActivity(payload: any, contribution_id: string): Observable<HttpResponse<{ code: string }>> {
    const url = `${API_ROUTES.PROJECTS.POST}/${contribution_id}/${API_ROUTES.PROJECTS.CONTRIBUTIONLIKEDISLIKE}`;
    return this.http.post<HttpResponse<{ code: string }>>(`${url}`, payload);
  }

  public updateContributionStatus(id: any, payloadBody: { status: string, reason: string }): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(`${API_ROUTES.PROJECTS.CONTRIBUTIONAPPROVE}/${id}`, payloadBody);
  }

  public reportContribution(id: string, data: any, contributionID: string): Observable<HttpResponse<{ code: string }>> {
    const url = `${API_ROUTES.PROJECTS.GET}/${contributionID}/${id}/contribution-report`;
    return this.http.post<HttpResponse<{ code: string }>>(url, data);
  }

  public getDashboardExploreData(): Observable<HttpResponse<{ code: string, data: any }>> {
    return this.http.get<HttpResponse<{ code: string, data: any }>>(`${API_ROUTES.COMMON.DashboardData}`);
  }

  public getDashboardBannerList(): Observable<HttpResponse<{ code: string, banner: any }>> {
    return this.http.get<HttpResponse<{ code: string, banner: any }>>(`${API_ROUTES.COMMON.DashboardBanner}`);
  }

  public httpGetProjectByUser(appliedFilters: objectType = {}, page: number = 1, limit: number = 9): Observable<HttpResponse<{ count: number; data: Array<objectType> }>> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('limit', limit);
    if (appliedFilters && Object.keys(appliedFilters).length)
      for (let key in appliedFilters) { appliedFilters[key] ? (params = params.append(key, appliedFilters[key])) : ''; }
    return this.http.get<HttpResponse<{ count: number; data: Array<objectType> }>>(API_ROUTES.PROJECTS.POST, { params: params });
  }

  public httpGetArticleByUser(appliedFilters: objectType = {}, page: number = 1, limit: number = 9): Observable<HttpResponse<{ count: number; data: Array<objectType> }>> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('limit', limit);
    if (appliedFilters && Object.keys(appliedFilters).length)
      for (let key in appliedFilters) { appliedFilters[key] ? (params = params.append(key, appliedFilters[key])) : ''; }
    return this.http.get<HttpResponse<{ count: number; data: Array<objectType> }>>(API_ROUTES.ARTICLES.POST, { params: params });
  }

  public httpGetCampaignByUser(appliedFilters: objectType = {}, page: number = 1, limit: number = 9): Observable<HttpResponse<{ count: number; data: Array<objectType> }>> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('limit', limit);
    if (appliedFilters && Object.keys(appliedFilters).length)
      for (let key in appliedFilters) { appliedFilters[key] ? (params = params.append(key, appliedFilters[key])) : ''; }
    return this.http.get<HttpResponse<{ count: number; data: Array<objectType> }>>(API_ROUTES.CAMPAIGNS.POST, { params: params });
  }

  public updateProjectViewCount(id: any): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(`${API_ROUTES.PROJECTS.GET}/${id}/view`, {});
  }

  public updateCampaignViewCount(id: any): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(`${API_ROUTES.CAMPAIGNS.ALL}/${id}/view`, {});
  }

  public updateArticleViewCount(id: any): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(`${API_ROUTES.ARTICLES.GET}/${id}/view`, {});
  }

  public getContributionById(id: string, contributionId: string): Observable<HttpResponse<any>> {
    const url = API_ROUTES.PROJECTS.CONTRIBUTIONBYPROJECT + '?project_id=' + id + '&contribution_id=' + contributionId;
    return this.http.get<HttpResponse<any>>(`${url}`);
  }

  public updateProjectsContribution(payload: any, contributionId: string): Observable<HttpResponse<{ code: string }>> {
    const url = API_ROUTES.PROJECTS.CONTRIBUTION;
    return this.http.patch<HttpResponse<{ code: string }>>(`${url}/${contributionId}`, payload);
  }

  public httpGetSelfContributionList(projectId: string, limit: number, page: number, filter: objectType = {}): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('limit', limit);
    if (filter && Object.keys(filter).length)
      for (let key in filter) { filter[key] ? (params = params.append(key, filter[key])) : ''; }
    const url = API_ROUTES.PROJECTS.SELF_CONTRIBUTIONS;
    return this.http.get<HttpResponse<any>>(`${url}/${projectId}`, { params: params });
  }

  public httpGetExploreTabCount(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${API_ROUTES.EXPLORE_COUNT}?is_Active=true`);
  }

  public httpPendingComments(id: string, flag: string): Observable<HttpResponse<any>> {
    const url = flag == 'campaigns' ? 'campaigns' : flag == 'projects' ? 'projects' : flag == 'articles' ? 'articles' : '';
    const endUrl = flag == 'campaigns' ? 'campaign-comments-count' : flag == 'projects' ? 'project-comments-count' : flag == 'articles' ? 'article-comments-count' : '';
    return this.http.get<HttpResponse<any>>(`${url}/${id}/${endUrl}`);
  }


  public _allUserCampaigns(appliedFilters: objectType = {}, page: number, limit: number, user_id: string): Observable<HttpResponse<{ count: number; data: Array<objectType> }>> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('limit', limit);
    if (appliedFilters && Object.keys(appliedFilters).length)
      for (let key in appliedFilters) { appliedFilters[key] ? (params = params.append(key, appliedFilters[key])) : ''; }
    return this.http.get<HttpResponse<{ count: number; data: Array<objectType> }>>(`${API_ROUTES.USER_ACTIVITY.CAMPAIGNS}/${user_id}`, { params: params });
  }

  public _allUserProjects(appliedFilters: objectType = {}, page: number, limit: number, user_id: string): Observable<HttpResponse<{ count: number; data: Array<objectType> }>> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('limit', limit);
    if (appliedFilters && Object.keys(appliedFilters).length)
      for (let key in appliedFilters) { appliedFilters[key] ? (params = params.append(key, appliedFilters[key])) : ''; }
    return this.http.get<HttpResponse<{ count: number; data: Array<objectType> }>>(`${API_ROUTES.USER_ACTIVITY.PROJECTS}/${user_id}`, { params: params });
  }

  public _allUserArticles(appliedFilters: objectType = {}, page: number, limit: number, user_id: string): Observable<HttpResponse<{ count: number; data: Array<objectType> }>> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('limit', limit);
    if (appliedFilters && Object.keys(appliedFilters).length)
      for (let key in appliedFilters) { appliedFilters[key] ? (params = params.append(key, appliedFilters[key])) : ''; }
    return this.http.get<HttpResponse<{ count: number; data: Array<objectType> }>>(`${API_ROUTES.USER_ACTIVITY.ARTICLES}/${user_id}`, { params: params });
  }

}
