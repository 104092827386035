import { Directive, Input, HostBinding } from '@angular/core';
import { Subscription } from 'rxjs';
import { CoreService } from '../services';
import { CountrySchema } from '../interfaces';
@Directive({
    selector: '[appCountryFlag]'
})
export class CountryFlagDirective {
    @Input() countryId!: string;
    @HostBinding('class') flagClass: string | undefined;

    private subscription: Subscription = new Subscription();
    public countryList: CountrySchema[] = [];

    constructor(private coreService: CoreService) { this.getCountryList(); }

    ngOnInit() { this.flagClass = 'me-1 fi fi-' + this.getCountrySymbol(this.countryId); }

    getCountryList() {
        this.subscription.add(this.coreService.country$.subscribe((res) => {
            this.countryList = res && res.length ? res : [];
        }));
    }

    getCountrySymbol(countryId: string) {
        return this.countryList.find((ele) => ele.id === countryId)?.symbol?.toLowerCase()
    }
}