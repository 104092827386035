<div
  *ngIf="control && control?.errors && (control?.dirty || control?.touched)"
  class="text-danger"
  id="error-message"
>
  <div *ngIf="control?.errors?.required">
    {{ controlName }}
    {{ 'validation.isRequired' | translate }}
  </div>
  <div *ngIf="control.errors.maxlength">
    {{ controlName }}
    {{
      ('validation.shouldContainMinimum' | translate : {value : control.errors.maxlength['requiredLength']} ) + ' ' +
        ('validation.characters' | translate)
    }}
  </div>
  <div *ngIf="control.errors.minlength">
    {{ controlName }}
    {{
      ('validation.shouldOnlyContain' | translate : {value : control.errors.minlength['requiredLength']} ) + ' ' +
        ('validation.characters' | translate)
    }}
  </div>
  <div *ngIf="control?.errors?.pattern">
    {{ controlName }}
    {{ 'validation.isInvalid' | translate }}
  </div>
  <div
    *ngIf="
      control?.errors?.hasCapitalCase ||
      control?.errors?.hasSmallCase ||
      control?.errors?.hasSpecialCharacters ||
      control?.errors?.hasNumber ||
      control?.errors?.canContainOnlyAlphabets
    "
  >
    {{ controlName }}
    {{
      control?.errors?.hasCapitalCase
        ? ('validation.hasAtleastOneUpperCaseLetter' | translate)
        : control?.errors?.hasSmallCase
        ? ('validation.hasAtleastOneSmallCaseLetter' | translate)
        : control?.errors?.hasSpecialCharacters
        ? ('validation.hasAtleastOneSpecialCharacter' | translate)
        : control?.errors?.hasNumber
        ? ('validation.hasAtleastOneNumericCharacter' | translate)
        : control?.errors.canContainOnlyAlphabets
        ? ('validation.canContainOnlyAlphabets' | translate)
        : ('validation.isInvalid' | translate)
    }}
  </div>
  <div *ngIf="control.errors.editorMaxChar">{{ controlName }}
    {{ 'validation.shouldOnlyContain5000Characters' | translate }}</div>
    <div *ngIf="control.errors.editorMinChar">{{ controlName }}
    {{ 'validation.minimumShouldContain25Characters' | translate }}</div>
    
</div>
