import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services';
import { Router } from '@angular/router';
import { environment } from 'src/environment/environment';

@Component({
  selector: 'app-win-point',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './win-point.component.html',
  styleUrls: ['./win-point.component.scss']
})
export class WinPointComponent implements OnInit, OnDestroy{
  private subscription: Subscription = new Subscription();
  public imageUrl: string = environment.imageUrl;
  public heading: string = '';
  public points: string = '';
  public pointId: string = '';
  public total_point: number = 0
  public badgeId: string = '';
  public badge_name: string = '';
  public badge_iamge: string = ''

  constructor( private activemodal: NgbActiveModal, private commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.readPoint();
    this.getPointCount();
  }

  patchData(obj?: any): void {
    this.heading = obj?.activity_type;
    this.points = obj?.points
    this.pointId = obj?.id
    this.badgeId = obj?.badge_id
    this.badge_name = obj?.badges
    this.badge_iamge = obj?.badge_image?.badge_image
  }

  closemodal() {
    this.activemodal?.close('close');
  }

  readPoint() {
    if(this.pointId){
      this.subscription.add(
        this.commonService.httpUpdatePointStatus(this.pointId).subscribe({
          next: () => {
          },
        }),
      );
    }
    if(this.badgeId){
      this.subscription.add(
        this.commonService.httpUpdateBadgeStatus(this.pointId).subscribe({
          next: () => {
          },
        }),
      );
    }
  }

  getPointCount() {
    this.subscription.add(
      this.commonService.httpGetTotalPoint().subscribe(({ data }) => {
        this.total_point = data && data?.totalPoints ? data?.totalPoints : 0;
      }),
    );
  }

  goToLeaderBoard(){
    this.router.navigate(['/post-auth/my-profile/leaderboard'])
    this.activemodal?.close('close');
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }

}
