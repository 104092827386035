import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo',
  standalone: true,
  pure: true,
})
export class LimitToPipe implements PipeTransform {
  transform(value: string, limit: number = 50) {
    if (value && value.length > limit) {
      const actualLength = value ? value.replace(/&nbsp;/g, ' ').replace(/<[^>]*>/g, '') : '';
      if (value && actualLength.length > limit) {
        return actualLength.slice(0, limit) + '...';
      } else {
        return actualLength + '...'; 
      }
    } else {
      return value;
    }
  }
}
