import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalSchema } from '../../interfaces';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent {
  public heading:string=''
  public subHeading:string=''
  public event!: DeleteModalSchema;

  constructor(private activemodal:NgbActiveModal){
  }

  closemodal(){
    this.activemodal.close()
  }
  submit(){
    this.activemodal.close('DELETE');
  }

  patchData(event: DeleteModalSchema): void {
    this.heading = event.heading;
    this.subHeading = event.subHeading;
  }
}
