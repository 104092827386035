<!-- Modal -->

<div class="modal-content border-0">
    <div class="modal-header border-0 justify-content-center">
      <h2 class="fw-700 fs-4 text-center my-1">Edit Project</h2>
      <button type="button" (click)="closemodal()" class="btn-close"></button>
    </div>
    <hr class="opacity-1 m-0">
    <div class="modal-body border-0 mt-3">
       
      <div class="row">
        <div class="col-lg-5">
          <div class="wrap position-relative">
          <input type="file" id="images-upload" class="position-absolute z-99999">
          <label for="" for="images-upload" class="label-upload position-relative cursor-pointer minh-280 images-upload text-center text-center bg-grey p-4 p-4 rounded-3 w-100 d-flex justify-content-center flex-column">
            <i class="fas fa-upload"></i>
            <p  class="fw-600 text-black mb-1 mt-2 fs-15">
              Click here to upload images.
            </p>
            <p class="text-grey-2 fs-12 m-0 text-center"> Images with max. 4 MB</p> 
          </label>

          <div class="wrap slider-view position-absolute top-0 d-none">
            <div class="title-img">
                <img src="https://th-thumbnailer.cdn-si-edu.com/vSnitgUqCQCRSx7mkHZtHZHry4U=/1072x720/filters:no_upscale()/https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer/04/8e/048ed839-a581-48af-a0ae-fac6fec00948/gettyimages-168346757_web.jpg" alt="" class="img-fluid radius-15">
            </div>
            <div class="thumbnails d-flex justify-content-center mt-3">
                <div class="thumb-icon me-2">
                    <img src="https://th-thumbnailer.cdn-si-edu.com/vSnitgUqCQCRSx7mkHZtHZHry4U=/1072x720/filters:no_upscale()/https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer/04/8e/048ed839-a581-48af-a0ae-fac6fec00948/gettyimages-168346757_web.jpg" alt="" class="img-fluid rounded-1">
                </div>
                <div class="thumb-icon me-2">
                    <img src="https://th-thumbnailer.cdn-si-edu.com/vSnitgUqCQCRSx7mkHZtHZHry4U=/1072x720/filters:no_upscale()/https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer/04/8e/048ed839-a581-48af-a0ae-fac6fec00948/gettyimages-168346757_web.jpg" alt="" class="img-fluid rounded-1">
                </div>
                <div class="thumb-icon me-2">
                    <img src="https://th-thumbnailer.cdn-si-edu.com/vSnitgUqCQCRSx7mkHZtHZHry4U=/1072x720/filters:no_upscale()/https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer/04/8e/048ed839-a581-48af-a0ae-fac6fec00948/gettyimages-168346757_web.jpg" alt="" class="img-fluid rounded-1">
                </div>
                <div class="thumb-icon me-2">
                    <img src="https://th-thumbnailer.cdn-si-edu.com/vSnitgUqCQCRSx7mkHZtHZHry4U=/1072x720/filters:no_upscale()/https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer/04/8e/048ed839-a581-48af-a0ae-fac6fec00948/gettyimages-168346757_web.jpg" alt="" class="img-fluid rounded-1">
                </div>
                <div class="thumb-icon me-2">
                    <img src="https://th-thumbnailer.cdn-si-edu.com/vSnitgUqCQCRSx7mkHZtHZHry4U=/1072x720/filters:no_upscale()/https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer/04/8e/048ed839-a581-48af-a0ae-fac6fec00948/gettyimages-168346757_web.jpg" alt="" class="img-fluid rounded-1">
                </div>
            </div>
        </div>


        </div>
        </div>
        <div class="col-lg-7">
          <div class="form-group mb-3">
            <label for="" class="fs-14 fw-600 text-grey-2 mb-2">Project title</label>
            <input type="text" placeholder="Enter title" class="form-control">
          </div>
          <div class="form-group mb-3">
            <label for="" class="fs-14 fw-600 text-grey-2 mb-2 w-100">Description</label>
            <textarea name="" id="" cols="15" rows="8" placeholder="Enter description" class="form-control"></textarea>
          </div>
          <div class="form-group mb-3">
            <label for="" class="fs-14 fw-600 text-grey-2 mb-2">Add Tags</label>
            <div class="input-btn position-relative">
            <input type="text" placeholder="Enter keywords" class="form-control pe-5">
             <button class="minw-auto btn btn-secondary rounded-2 position-absolute right-0 top-0 btn-sm"><span class="rounded-pill px-1 bg-white text-grey-2">+</span> Add</button> 
          </div>
              
            <div class="tags mt-3">
              <span class="me-2 mb-2 text-primary fs-14 cursor-pointer position-relative">#tags1
                <strong class="close"><i class="fas fa-window-close"></i></strong> 
              </span> 
              </div>
          </div>
        </div>
      </div>
      
    </div>
    <hr class="opacity-1 m-0">
    <div class="modal-footer border-0 justify-content-center mt-0">
      <button type="button" (click)="submit()" class="btn btn-primary rounded-pill">
        Save changes
      </button>
    </div>
  </div>
  