<div class="px-2">
  <div class="modal-header justify-content-center border-0 pb-0">
    <div class="form-title" id="staticBackdropLabel">
      <h4 class="text-center mt-3 mb-1">{{'signup.signup' | translate}}</h4>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="px-2 pb-5">
    <form [formGroup]="signupForm" (ngSubmit)="submitForm()" novalidate>
      <div class="row mt-3 mx-0">
        <div class="form-group mb-3 col-md-12">
          <label for="name" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14">{{'signup.name' | translate}}<span
              class="text-danger">*</span></label>
          <div class="group">
            <input type="text" class="form-control" placeholder="{{'signup.namePlaceholder' | translate}}" aria-label="name"
              aria-describedby="basic-addon1" formControlName="name"
              [ngClass]="{ 'outline-red': f['name'].invalid && f['name'].touched}" />
          </div>
          <app-validation-error [control]="f['name']" [controlName]="('signup.name' | translate)" class="fs-14">
          </app-validation-error>
        </div>
        <div class="form-group mb-3 col-md-12">
          <label for="email" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14">{{'signup.email' | translate}} <span
              class="text-danger">*</span></label>
          <div class="group">
            <input type="email" class="form-control" placeholder="{{'signup.emailPlaceholder' | translate}}" aria-label="Username"
              aria-describedby="basic-addon1" formControlName="email"
              [ngClass]="{ 'outline-red': f['email'].invalid && f['email'].touched }" />
          </div>
          <app-validation-error [control]="f['email']" [controlName]="('signup.email' | translate)" class="fs-14">
          </app-validation-error>
        </div>

        <div class="form-group mb-3 col-md-12">
          <label for="password" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14">{{'signup.password' | translate}}
            <span class="text-danger">*</span></label>
          <div class="group position-relative">
            <input [type]="showPassword ? 'text' : 'password'" class="form-control" placeholder="{{'signup.passwordPlaceholder' | translate}}"
              aria-label="password" aria-describedby="basic-addon1" formControlName="password"
              [ngClass]="{'outline-red': f['password'].invalid && f['password'].touched}" />
            <img [src]="showPassword ? 'assets/images/password-hide.png' : 'assets/images/password-view.png'"
              style="left: auto;right: 15px;top: 12px; " class="input-icon link position-absolute"
              (click)="passwordToggle()">
          </div>
          <app-validation-error class="fs-14" [control]="f['password']" [controlName]="('signup.password' | translate)">
          </app-validation-error>
        </div>
        <div class="form-group mb-3 col-md-12">
          <label for="confirm_password" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14">{{'signup.confirmPassword' | translate}}
            <span class="text-danger">*</span></label>
          <div class="group position-relative">
            <input [type]="showConfirmPassword ? 'text' : 'password'" class="form-control"
              placeholder="{{'signup.confirmPasswordPlaceholder' | translate}}" aria-label="confirm_password" aria-describedby="basic-addon1"
              formControlName="confirm_password" [ngClass]="{ 'outline-red': f['confirm_password'].touched && (f['confirm_password'].invalid ||
               f['confirm_password'].value !== f['password'].value)}" />
            <img [src]="showConfirmPassword ? 'assets/images/password-hide.png' : 'assets/images/password-view.png'"
              style="left: auto;right: 15px;top: 12px; " class="input-icon link position-absolute"
              (click)="confirmPasswordToggle()">
          </div>
          <app-validation-error class="fs-14" [control]="f['confirm_password']" [controlName]="('signup.confirmPassword' | translate)">
          </app-validation-error>
          <div class="text-danger fs-14"
            *ngIf=" f['confirm_password'].touched && (f['confirm_password'].invalid || f['confirm_password'].value !== f['password'].value)">
            {{'signup.confirmPasswordMustSame' | translate}}
          </div>
        </div>

        <div class="form-group mb-3 col-md-12">
          <label for="contact" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14">{{'signup.contact' | translate}}</label>
          <div class="group">
            <input type="tel" class="form-control" placeholder="{{'signup.contactPlaceholder' | translate}}" aria-label="phone"
              aria-describedby="basic-addon1" formControlName="contact"
              [ngClass]="{ 'outline-red': f['contact'].invalid && f['contact'].touched}" />
          </div>
          <app-validation-error class="fs-14" [control]="f['contact']" [controlName]="('signup.contact' | translate)">
          </app-validation-error>
        </div>

        <div class="form-group mb-3 col-md-6">
          <label for="city" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14">{{'signup.city' | translate}}</label>
          <div class="group">
            <input type="text" class="form-control" placeholder="{{'signup.cityPlaceholder' | translate}}" aria-label="city"
              aria-describedby="basic-addon1" formControlName="city"
              [ngClass]="{'outline-red': f['city'].invalid && f['city'].touched}" />
          </div>
          <app-validation-error [control]="f['city']" [controlName]="('signup.city' | translate)" class="fs-14">
          </app-validation-error>
        </div>
        <div class="form-group mb-3 col-md-6">
          <label for="country_id" id="country" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14">{{'signup.country' | translate}}
            <span class="text-danger">*</span></label>
          <div class="group">
            <select class="form-select form-control fs-14" id="countryId" formControlName="country_id"
              [ngClass]="{'outline-red': f['country_id'].invalid && f['country_id'].touched }">
              <option disabled value="">{{'signup.countryPlaceholder' | translate}}</option>
              <ng-container *ngFor="let country of coreService.country$ | async; let i = index">
                <option [id]="i" [ngValue]="country.id">{{ country.name }}</option>
              </ng-container>
            </select>
          </div>
          <app-validation-error class="fs-14" [control]="f['country_id']" [controlName]="('signup.country' | translate)">
          </app-validation-error>
        </div>

        <div class="form-group mb-3 col-md-12">
          <label for="userInterests" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14">{{'signup.interest' | translate}}
            <span class="text-danger">*</span></label>
          <div class="group">
            <app-multi-select-dropdown  id="intrest" [placeholder]="('signup.interestPlaceholder' | translate)" [items]="interestList" (shareCheckedList)="selectedInterestList($event)"
              [ngClass]="{ 'outline-red': f['userInterests'].invalid && f['userInterests'].touched}">
            </app-multi-select-dropdown>
          </div>
          <app-validation-error class="fs-14" [control]="f['userInterests']" [controlName]="('signup.interest' | translate)">
          </app-validation-error>
        </div>
        <div class="form-group mb-3 col-md-4">
          <label for="profile_type" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14 position-relative">
            {{'signup.profileViewOption' | translate}}
            <div class="tool-info_icon">
              <i class="fas fa-info-circle ms-3 text-grey-2 top-plus-1 position-relative before-none cursor-pointer">
                <img src="assets/images/profile/info.svg" />
              </i>
              <div class="tooltip-body">
                <ul>
                  <li class="mb-2"> <strong>{{'signup.public' | translate}} : </strong> {{'signup.everyoneCanSee' | translate}} </li>
                  <li> <strong>{{'signup.private' | translate}} : </strong> {{'signup.onlyAdminCanSee' | translate}} </li>
                </ul>
              </div>
            </div>
          </label>
          <div class="profile-right__profile-type position-relative">
            <label class="switch">
              <input type="checkbox" (change)="setTypeProfileValue()"
                [checked]=" f['profile_type'].value && f['profile_type'].value == 'public' ? true : false" />
              <span class="slider round top-0 right-0 bottom-0 left-0">
                <p class="private fw-600">{{'signup.private' | translate}}</p>
                <p class="public fw-600 text-white">{{'signup.public' | translate}}</p>
              </span>
            </label>
          </div>
        </div>
        <div class="form-group mb-3 w-100">
          <label for="gender" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14">{{'signup.gender' | translate}}</label>
          <div class="d-flex">
            <ng-container *ngFor="let item of genderArray; let i = index">
              <div class="form-check" [ngClass]="{ 'ms-3': i !== 0 }">
                <input class="form-check-input position-relative top-plus-1" type="radio" name="flexRadioDefault"
                  [attr.id]="'flexRadioDefault' + i" [checked]="f['gender'].value == item ? true : false"
                  (click)="setGenderValue(item)" />
                <label class="form-check-label fs-14" [attr.for]="'flexRadioDefault' + i"> {{ (item) | translate }} </label>
              </div>
            </ng-container>
          </div>
          <app-validation-error [control]="f['gender']" [controlName]="('signup.gender' | translate)">
          </app-validation-error>
        </div>
        <p class="f-12" style="font-size: 12px;">By selecting the Sign-up button, you are acknowledging that you have reviewed our <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>, <a routerLink="/community-guidelines" target="_blank">Community Guidelines</a>, <a routerLink="/terms-conditions" target="_blank">Terms &amp; Conditions</a>, and <a routerLink="/copyright" target="_blank">Copyright information</a>. Your action will be regarded as your agreement to share the information with the Youth Action Platform system.</p>
      </div>
      <button type="submit" class="btn btn-primary rounded-pill mx-auto d-block mt-4 px-5 w-100"> {{'signup.signup' | translate}} </button>
      <div class="or my-4 position-relative">
        <hr class="opacity-1" /> <span>{{'signup.or' | translate}}</span>
      </div>
      <div class="google-btn position-relative">
        <button class="btn rounded-pill mx-auto d-block mt-4 px-3 w-100 g-btn"><svg viewBox="0 0 1152 1152" focusable="false" aria-hidden="true" role="img" class="spectrum-Icon spectrum-Icon--sizeM" data-social-button-type="icon" data-social-white-background="true"><path d="M1055.994 594.42a559.973 559.973 0 0 0-8.86-99.684h-458.99V683.25h262.28c-11.298 60.918-45.633 112.532-97.248 147.089v122.279h157.501c92.152-84.842 145.317-209.78 145.317-358.198z" fill="#4285f4"></path><path d="M588.144 1070.688c131.583 0 241.9-43.64 322.533-118.07l-157.5-122.28c-43.64 29.241-99.463 46.52-165.033 46.52-126.931 0-234.368-85.728-272.691-200.919H152.636v126.267c80.19 159.273 245 268.482 435.508 268.482z" fill="#34a853"></path><path d="M315.453 675.94a288.113 288.113 0 0 1 0-185.191V364.482H152.636a487.96 487.96 0 0 0 0 437.724z" fill="#fbbc05"></path><path d="M588.144 289.83c71.551 0 135.792 24.589 186.298 72.88l139.78-139.779C829.821 144.291 719.504 96 588.143 96c-190.507 0-355.318 109.21-435.508 268.482L315.453 490.75c38.323-115.19 145.76-200.919 272.691-200.919z" fill="#ea4335"></path></svg><span class="ms-2">Continue with Google</span></button>
      <asl-google-signin-button #googlebutton type="standard" size="large" shape="circle"
        theme="outline"></asl-google-signin-button></div>
      <p class="text-center account-div fs-14 mb-0 mt-3 text-grey-custom">
        {{'signup.alreadyHaveAnAccount' | translate}} <a (click)="renderLoginModal()" class="fw-600">{{'signup.login' | translate}}</a>
      </p>
    </form>
  </div>
</div>