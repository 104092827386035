import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-tabs-ui',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
  templateUrl: './tabs-ui.component.html',
  styleUrls: ['./tabs-ui.component.scss'],
})
export class TabsUiComponent {
  @Input() tabsArray: Array<{ title: string; path: string; count: any }> = [];
}
