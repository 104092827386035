import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { CoreService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { CountryFlagModule } from '../../country-flag.module';

@Component({
  selector: 'app-language-dropdown',
  standalone: true,
  imports: [CommonModule, CountryFlagModule],
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss']
})
export class LanguageDropdownComponent {
  @Output() languageCodeEvent = new EventEmitter<string>();
  public loading: boolean = false;
  private subscription: Subscription = new Subscription();
  public countryList: Array<any> = [];
  public selectedLanguage: any = { name: 'English', code: 'en', country: 'United Kingdom'};
  public languageDropdown = [
    { name: 'हिंदी', code: 'hi', country: 'India'},
    { name: 'English', code: 'en', country: 'United Kingdom'},
    { name: 'Tiếng Việt', code: 'vi', country: 'Vietnam'},
  ]

  constructor(private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {
    this.getCountryList();
    const currentLanguage = localStorage.getItem('selectedLang');
    this.selectedLanguage = this.languageDropdown.find(ele=> ele.code === currentLanguage);
    this.sendLanguageCode(this.selectedLanguage.code);
  }

  getCountryList() { 
    this.subscription.add(this.coreService.country$.subscribe((res) => { 
    this.countryList = res && res.length ? res : [];}));
  }

  getCountryById(countryName: any) { 
    const selectedCountry = this.countryList.find((ele: any)=> ele?.name?.toLowerCase() === countryName?.toLowerCase())
    return selectedCountry ? selectedCountry?.id : ''
  }

  changeLanguage(lang: any){
    this.loading = true;
    this.translate.use(lang.code);
    localStorage.setItem('selectedLang',lang.code);
    this.selectedLanguage = lang;
    this.sendLanguageCode(this.selectedLanguage.code);
    setTimeout(()=>{this.loading = false},0);
  }

  sendLanguageCode(value: string) {
    this.languageCodeEvent.emit(value);
  }
}
