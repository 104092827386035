import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CommonService,
  CustomMessageService,
  EmailRegex,
  LoginForm,
  MainService,
  ProfileSchema,
  UserAuthService,
} from 'src/app/shared';
import { Subscription, switchMap } from 'rxjs';
import { Validator } from 'src/app/core/custom-validators/custom-validations';
import { TokenStorageService } from 'src/app/core/services';
import { ManageProfileService } from '../../modules/post-auth-pages/modules/manage-profile/service/manage-profile.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm!: LoginForm;
  public showPassword: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private authService: SocialAuthService,
    private commonService: CommonService,
    private userAuthService: UserAuthService,
    private toasterService: CustomMessageService,
    private mainService: MainService,
    private tokenStorageService: TokenStorageService,
    private manageProfileService: ManageProfileService,
  ) {
    this.loginForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(EmailRegex),
          Validators.maxLength(250),
          Validator.noWhiteSpaceValidator,
        ],
      ],
      password: ['', [Validators.required]],
    }) as LoginForm;
  }

  ngOnInit(): void {
    this.ssoLogin();
    this.updateUseruid();
  }

  public renderModal(flag: string) {
    this.activeModal.close(flag);
  }

  public close(flag?: string) {
    this.activeModal.close(flag);
  }

  public ssoLogin() {
    this.subscription.add(
      this.authService.authState.subscribe({
        next: (res) => {
          if (res && res.idToken) {
            this.googleLogin(res.idToken);
          }
        },
        error: (err) => {},
      }),
    );
  }

  private googleLogin(token: string) {
    const body = { token: token };
    if (body) {
      this.subscription.add(
        this.userAuthService.ssoLogin(body).subscribe({
          next: ({ data }) => {
            if (data && data.accessToken) {
              this.tokenStorageService.setTokenSession = data.accessToken;
              this.tokenStorageService.loginType = 'SSO';
              this.updateUseruid();
              // setTimeout(() => {
              //   console.log(this.mainService)
              //   if (this.mainService.getUserDetail?.detail?.profile?.uid && this.mainService.getUserDetail?.detail?.profile?.uid !== null) { this.FirebaseLogin(); }
              //   else {
              //     console.log('else conditions'); this.FirebaseUser(); }
              // }, 1000);
            }
            this.toasterService.show('Successfully logged in', 'success');
            this.close('LOGIN_TRUE');
          },
        }),
      );
    } else {
      this.toasterService.show('Unable to authentication with google', 'error');
    }
  }

  public FirebaseUser() {
    const name = this.mainService.getUserDetail.detail.profile.name;
    const email = this.mainService.getUserDetail.detail.email;
    const password = 'Pass@123';
    const alisedId = this.mainService.getUserDetail.detail.id;
    const uid = this.mainService.getUserDetail.detail.id;
    this.userAuthService
      .signUp(email, password)
      .pipe(
        switchMap(({ user: { uid } }) => {
          localStorage.setItem('fireBaseUserId', uid);
          return this.userAuthService.addUser({
            uid,
            email,
            displayName: name,
            alisedId: alisedId,
          });
        }),
      )
      .subscribe(
        (res) => {
          window.location.reload();
        },
        (err) => {},
      );
  }

  public FirebaseLogin() {
    // const email = 'aksha@signitysolutions.com';
    const email = this.mainService.getUserDetail.detail.email;
    const password = 'Pass@123';
    this.userAuthService
      .login(email, password)
      .pipe()
      .subscribe((res: any) => {
        if (res) {
          localStorage.setItem('fireBaseUserId', res?.user?.uid);
        }
        if (res.code === 'auth/user-not-found') {
          this.FirebaseUser();
        }
      });
  }

  public submitForm() {
    if (this.loginForm.valid) {
      this.subscription.add(
        this.userAuthService.httpLogin(this.loginForm.value).subscribe({
          next: ({ data }) => {
            if (data && data.accessToken) {
              this.tokenStorageService.setTokenSession = data.accessToken;
              this.tokenStorageService.loginType = 'EMAIL';
              setTimeout(() => {
                if (
                  this.mainService.getUserDetail.detail.profile.uid !== null
                ) {
                  this.FirebaseLogin();
                } else {
                  this.FirebaseUser();
                }
              }, 100);
            }
            this.toasterService.show('Successfully logged in', 'success');
            this.close('LOGIN_TRUE');
          },
        }),
      );
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  updateUseruid() {
    this.commonService.profileData$.subscribe((res) => {
      if (res) {
        const data = res as ProfileSchema;
        data.profile && data.profile.name ? data.profile.name : '';
        if (
          data &&
          localStorage.getItem('fireBaseUserId') &&
          (!data.profile.uid || data.profile.uid === '')
        ) {
          data.uid = localStorage.getItem('fireBaseUserId');
          this.updateUserProfile(data);
        } else {
          if (
            this.mainService.getUserDetail?.detail?.profile?.uid &&
            this.mainService.getUserDetail?.detail?.profile?.uid !== null
          ) {
            this.FirebaseLogin();
          } else {
            this.FirebaseUser();
          }
        }
      }
    });
  }

  updateUserProfile(requestBody: any) {
    this.subscription.add(
      this.commonService
        .patchUpdateUserUUI(
          this.mainService.getUserDetail.detail.id,
          requestBody,
        )
        .subscribe({
          next: ({ data }) => {},
        }),
    );
  }

  public get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  passwordToggle() {
    this.showPassword = !this.showPassword;
  }
}
