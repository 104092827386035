import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryFlagDirective } from '../../directive/country-flag.directive';
import { CountryFlagModule } from '../../country-flag.module';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-country-view',
  standalone: true,
  imports: [CommonModule, CountryFlagModule],
  templateUrl: './country-view.component.html',
  styleUrls: ['./country-view.component.scss']
})
export class CountryViewComponent {
  public countryData: any =[];
  public countryList: any =[];
  public heading: string = '';
  public body: string = '';
  constructor( private activemodal: NgbActiveModal) { }

  patchData(event?: any, list?: any, obj?: any): void {
    this.countryData = event;
    this.countryList = list;
    this.heading = obj?.heading;
    this.body = obj?.body
  }

  getCountryName(countryId: string) { return this.countryList.find((ele: any) => ele.id === countryId)?.name }

  closemodal() {
    this.activemodal.close();
  }

}
