import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/shared';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements OnInit{

  constructor(private coreService: CoreService){}

  ngOnInit(){
    this.coreService.setCountries();
    this.coreService.setInterests();
    this.coreService.setRestrictedWords();
  }

}
