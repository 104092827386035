import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { StateService } from '../states';
import { CountrySchema, HttpResponse, InterestSchema } from '../interfaces';
import { Observable, Subscription } from 'rxjs';
import { API_ROUTES } from '../routes';

@Injectable({
  providedIn: 'root',
})
export class CoreService
  extends StateService<{ country: Array<CountrySchema>; interests: Array<InterestSchema>; restrictedWords: Array<any>; }>
  implements OnDestroy {
  public country$: Observable<CountrySchema[]> = this.select((state) => state && (state.country as CountrySchema[]));
  public interests$: Observable<InterestSchema[]> = this.select((state) => state && (state.interests as InterestSchema[]));
  public restrictedWords$: Observable<any> = this.select((state) => state && (state.restrictedWords as any));
  private subscription: Subscription = new Subscription();

  constructor(private http: HttpClient) { super({ interests: [], country: [], restrictedWords: [] }); }

  public setCountries() {
    this.subscription.add(
      this.http.get<HttpResponse<CountrySchema[]>>(API_ROUTES.COMMON.COUNTRY).subscribe((res) => {
        if (res && res.data) { this.setState({ country: res.data.length ? res.data : [] }); }
      }),
    );
  }

  public setInterests() {
    this.subscription.add(
      this.http.get<HttpResponse<InterestSchema[]>>(API_ROUTES.COMMON.INTEREST).subscribe((res) => {
        if (res && res.data) { this.setState({ interests: res.data.length ? res.data : [] }); }
      }),
    );
  }

  public setRestrictedWords() {
    this.subscription.add(
      this.http.get<HttpResponse<any>>(API_ROUTES.COMMON.RESTRICTED_CHAT).subscribe((res) => {
        if (res && res.data) { this.setState({ restrictedWords : res.data.chatWords.length ? res.data.chatWords : [] }); }
      }),
    );
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }
}
