import { Injectable, inject } from '@angular/core';
import { AUTH_KEYS } from '../enums';
import { CookieService } from 'ngx-cookie-service';
import { AESEncryptDecryptServiceService } from 'src/app/shared';
import { LoginType } from '../types';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TokenStorageService {
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private cookieService: CookieService, private aes: AESEncryptDecryptServiceService) { }

  public set setTokenSession(data: string) {
    if (data) {
      const tokenData = data ? this.aes.encryptData(data) : '';
      this.cookieService.set(AUTH_KEYS.USER_TOKEN, tokenData, { path: '/' });
      this.isLoggedIn$.next(true);
    }
  }

  public get getTokenSession() {
    let tokenSession = this.cookieService.get(AUTH_KEYS.USER_TOKEN);
    if (tokenSession) { return this.aes.decryptData(tokenSession); }
    else { return; }
  }

  public set loginType(type: LoginType) {
    if (type) {
      const loginType = type ? this.aes.encryptData(type) : '';
      this.cookieService.set(AUTH_KEYS.USER_LOGIN_TYPE, loginType, { path: '/', });
    }
  }

  public get loginType() {
    let loginType = this.cookieService.get(AUTH_KEYS.USER_LOGIN_TYPE);
    if (loginType) { return this.aes.decryptData(loginType); }
    else { return 'NOT_DEFINED'; }
  }

  public clearTokenSession() {
    this.deleteCookies();
    
    const guide = localStorage.getItem('guide')
    const loginGuide = localStorage.getItem('loginGuide')
    const badge = localStorage.getItem('badge')
    window.localStorage.clear();
    localStorage.setItem('guide',guide ? guide : '')
    localStorage.setItem('loginGuide',loginGuide ? loginGuide : '')
    localStorage.setItem('badge',badge ? badge : '')


    window.sessionStorage.clear();
    this.isLoggedIn$.next(false);
  }

  // function to delete cookies
  deleteCookies() {
    this.cookieService.delete(AUTH_KEYS.USER_TOKEN, '/');
    this.cookieService.delete(AUTH_KEYS.USER_LOGIN_TYPE, '/');
    this.cookieService.delete(AUTH_KEYS.USER_SESSION, '/');
    this.cookieService.deleteAll('/');
  }
}
