<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title text-center fw-700 w-100">
        {{'changePassword.changePassword' | translate}}
      </h5>
      <!-- <button type="button" class="btn-close" (click)="closeModal()"></button> -->
    </div>
    <form [formGroup]="changePasswordForm" (ngSubmit)="submitForm()">
      <div class="modal-body">
        <div class="form-group mb-3">
          <label for="" class="mb-2 fw-600"
            >Old Password <span class="text-danger">*</span></label
          >
          <input
            showError
            formControlName="old_password"
            name="old_password"
            id="old_password"
      
            type="password"
            class="form-control"
            placeholder="Enter Confirm Password"
            [ngClass]="{
              'outline-red':
                f['old_password'].invalid && f['old_password'].touched
            }"
          />
          <app-validation-error
            [control]="f['old_password']"
            [controlName]="'Old Password'"
          />
        </div>
        <div class="form-group mb-3">
          <label for="" class="mb-2 fw-600"
            >New Password <span class="text-danger">*</span></label
          >
          <input
            showError
            name="newPassword"
            id="newPassword"
            type="password"
            class="form-control"
            placeholder="{{'changePassword.newPassword' | translate}}"
            formControlName="newPassword"
            [ngClass]="{
              'outline-red': f['newPassword'].invalid && f['newPassword'].touched
            }"
          />
          <app-validation-error
            [control]="f['newPassword']"
            [controlName]="('changePassword.newPassword' | translate)"
          />
        </div>
        <div class="form-group mb-3">
          <label for="" class="mb-2 fw-600"
            >{{'changePassword.confirmPassword' | translate}} <span class="text-danger">*</span></label
          >
          <input
            showError
            formControlName="confirmPassword"
            name="confirmPassword"
            id="confirmPassword"
      
            type="password"
            class="form-control"
            placeholder="{{'changePassword.confirmPassword' | translate}}"
            [ngClass]="{
              'outline-red':
                f['confirmPassword'].invalid && f['confirmPassword'].touched
            }"
          />
          <app-validation-error
            [control]="f['confirmPassword']"
            [controlName]="('changePassword.confirmPassword' | translate)"
          />
        </div>
        <div
          class="danger"
          *ngIf="
            f['confirmPassword'].touched &&
            f['confirmPassword'].value !== f['newPassword'].value
          "
        >
          <div class="mb-2">{{'changePassword.newAndConfirmPasswordMustSame' | translate}}</div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          type="button"
          class="btn btn-secondary rounded-pill"
          (click)="closeModal()" >{{'changePassword.close' | translate}}
        </button>
        <button type="submit" [disabled]="!changePasswordForm.valid"  class="btn btn-primary rounded-pill">
          Change Password
        </button>
      </div>
    </form>
  </div>