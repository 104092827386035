import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent {

  constructor(private activemodal:NgbActiveModal){

  }
  closemodal(){
    this.activemodal.close()
  }
  submit(){
    this.activemodal.close('DELETE');
  }
}
