<!-- Modal -->

<div class="modal-content border-0">
  <div class="modal-header justify-content-center">
    <h2 class="fs-4">{{'modal.report' | translate}}</h2>
    <button type="button" (click)="closemodal()" class="btn-close"></button>
  </div>
  <div class="modal-body border-0 py-0 px-4 mt-4">
    <ng-container *ngFor="let item of reportsList; let i = index">
      <div class="form-check mb-2" *ngIf="item">
        <input class="form-check-input" type="radio" name="flexRadioDefault" (click)="selectReportName(item.name)"
          [attr.id]="'flexRadioDefault' + i" />
        <label class="form-check-label cursor-pointer" [attr.for]="'flexRadioDefault' + i">
          {{ item.name || '' }}
        </label>
      </div>
    </ng-container>
    <div *ngIf="reportName">
      <label class="form-check-label d-flex mt-3 fw-600">{{'modal.reportText' | translate}} </label>
      <input type="text" class="form-control" placeholder="{{'modal.enterText' | translate}}" aria-label="Username"
        aria-describedby="basic-addon1" [(ngModel)]="AdditionalInput" [ngModelOptions]="{ standalone: true }" />
    </div>
    <p class="guidelines fs-12 text-grey-custom mb-0 mt-4">
      {{'modal.reportDescription' | translate}}
    </p>
  </div>
  <div class="modal-footer border-0 justify-content-center mb-3">
    <button type="button" (click)="submit(AdditionalInput)" class="btn btn-primary">
      {{'modal.report' | translate}}
    </button>
  </div>
</div>