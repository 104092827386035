import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-mobile',
  templateUrl: './privacy-policy-mobile.component.html',
  styleUrls: ['./privacy-policy-mobile.component.scss']
})
export class PrivacyPolicyMobileComponent {

}
