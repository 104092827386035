import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Validator } from 'src/app/core/custom-validators/custom-validations';
import { CustomMessageService, EmailRegex, ForgotPasswordForm, UserAuthService } from 'src/app/shared';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public emailForm!: ForgotPasswordForm;
  private subscription: Subscription = new Subscription();

  constructor(private fb: FormBuilder, private activeModal: NgbActiveModal, private userAuthService: UserAuthService,
    private toasterService: CustomMessageService, private router: Router) {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(EmailRegex), Validators.maxLength(250), Validator.noWhiteSpaceValidator],
      ]
    }) as ForgotPasswordForm;
  }

  ngOnInit(): void { }

  public renderModal(flag: string) { this.activeModal.close(flag); }

  public close() { this.activeModal.close(); }

  public submitForm() {
    if (this.emailForm.valid) {
      this.subscription.add(this.userAuthService.forgotPassword(this.emailForm.value).subscribe({
        next: ({ data }) => {
          if (data && data.code) { this.toasterService.showHttpMessages(data.code, 'success'); }
          this.activeModal.close();
        },
      }));
    } else { this.emailForm.markAllAsTouched(); }
  }

  public get f(): { [key: string]: AbstractControl } { return this.emailForm.controls; }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }
}
