import { NgModule } from "@angular/core";
import { CountryFlagDirective } from "./directive/country-flag.directive";

@NgModule({
    declarations: [
      CountryFlagDirective
    ],
    exports: [
      CountryFlagDirective
    ],
  })
  export class CountryFlagModule { }
  