<div class="wrap slider-view mx-auto">
    <div class="wrap slider-view">
    <div class="thumbnails d-flex justify-content-center">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
          <div class="title-img">
            <img [src]="slide" alt="slide" width="100%" class="img-fluid" loading="lazy" />
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
  </div>