<!-- Modal -->

<div class="modal-content border-0">
  <div class="modal-header border-0 p-0">
    <button type="button" (click)="closemodal()" class="btn-close d-none"></button>
  </div>
  <div class="modal-body border-0 text-center m-0 p-0">
    <div class="cancel mb-3 mt-4">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="800px"
        height="800px"
        viewBox="0 0 32 32"
        enable-background="new 0 0 32 32"
        xml:space="preserve"
      >
        <g>
          <path
            fill="#808184"
            d="M16,0C11.726,0,7.708,1.664,4.687,4.687C1.665,7.708,0,11.727,0,16s1.665,8.292,4.687,11.313
                      C7.708,30.336,11.726,32,16,32s8.292-1.664,11.313-4.687C30.335,24.292,32,20.273,32,16s-1.665-8.292-4.687-11.313
                      C24.292,1.664,20.274,0,16,0z M26.606,26.606C23.773,29.439,20.007,31,16,31s-7.773-1.561-10.606-4.394S1,20.007,1,16
                      S2.561,8.227,5.394,5.394S11.993,1,16,1s7.773,1.561,10.606,4.394S31,11.993,31,16S29.439,23.773,26.606,26.606z"
          />
          <path
            fill="#808184"
            d="M22.01,9.989c-0.195-0.195-0.512-0.195-0.707,0L16,15.293l-5.303-5.304c-0.195-0.195-0.512-0.195-0.707,0
                      s-0.195,0.512,0,0.707L15.293,16L9.99,21.304c-0.195,0.195-0.195,0.512,0,0.707c0.098,0.098,0.226,0.146,0.354,0.146
                      s0.256-0.049,0.354-0.146L16,16.707l5.303,5.304c0.098,0.098,0.226,0.146,0.354,0.146s0.256-0.049,0.354-0.146
                      c0.195-0.195,0.195-0.512,0-0.707L16.707,16l5.303-5.304C22.206,10.501,22.206,10.185,22.01,9.989z"
          />
        </g>
      </svg>
    </div>
    <h2 class="fs-3">{{heading | translate}}</h2>
    <p class="fs-15 my-3 px-4"[innerHTML]="subHeading | translate" >
      
    </p>
  </div>
  <div class="modal-footer border-0 justify-content-center mt-0 mb-3">
    <button type="button" (click)="closemodal()" class="btn btn-outline-primary">
     {{'deleteModal.cancel' | translate}}
    </button>
    <button type="button" (click)="submit()" class="btn btn-danger">
      {{'deleteModal.yesDelete' | translate}}
    </button>
  </div>
</div>
