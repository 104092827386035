import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

type HeaderModal = 'LOGIN' | 'SIGNUP';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private modalSubject$: Subject<HeaderModal> = new Subject<HeaderModal>();

  constructor() { }

  public set _setRenderModal(flag: HeaderModal) {
    this.modalSubject$.next(flag);
  }

  public get renderModal() {
    return this.modalSubject$.asObservable();
  }
}
