import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class Validator {
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }
    return null;
  }

  static noSpecialCharsValidator(control: AbstractControl): ValidationErrors | null {
    const hasSpecialChars = /[^a-zA-Z0-9\s]/.test(control.value);
    return hasSpecialChars ? { noSpecialChars: true } : null;
  }

  static cannotContainAlphabets(control: AbstractControl): ValidationErrors | null {
    let val = control.value;
    if (val === null || val === '') return null;
    if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/))
      return { cannotContainAlphabets: true };
    return null;
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) { return null; }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  static noWhiteSpaceValidator(control: AbstractControl): ValidationErrors | null {
    const input = control.value || '';
    const isWhitespace = input.trim().length === 0;
    const isValid = !isWhitespace || input === '';
    return isValid ? null : { noWhiteSpaceValidator: true };
  }
}

export function editorMaxCharValidator(control: FormControl): ValidationErrors | null {
  const totalCount = control.value ? control.value.replace(/&nbsp;/g, ' ').replace(/<[^>]*>/g, '').length : 0;
  const isValid = !(totalCount > 5000);
  return isValid ? null : { editorMaxChar: true, count: totalCount };
}

export function editorMinCharValidator(control: FormControl): ValidationErrors | null {
  const totalCount = control.value ? control.value.replace(/&nbsp;/g, ' ').replace(/<[^>]*>/g, '').length : 0;
  const isValid = !(control.value?.length && totalCount < 25);
  return isValid ? null : { editorMinChar: true, count: totalCount };
}

export function editorMaxCount(editorText: string) {
  const totalCount = editorText ? editorText.replace(/&nbsp;/g, ' ').replace(/<[^>]*>/g, '').length : 0;
  return totalCount
}


