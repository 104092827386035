import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpResponse, TermPageType, TermsPageSchema } from '../interfaces';
import { API_ROUTES } from '../routes';
import { SafeHtml } from '@angular/platform-browser';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class TermPageService {
  constructor(private http: HttpClient, private commonService: CommonService,) { }

  public getTermPageDetail(
    type: TermPageType,
  ): Observable<HttpResponse<TermsPageSchema>> {
    return this.http
      .get<HttpResponse<TermsPageSchema>>(`${API_ROUTES.TERMS_PAGES}/${type}`)
      .pipe(
        map((res) => {
          if (res && res.data && res.data.description) {
            res.data.description = this.commonService.safeHTML(
              res.data.description as string,
            ) as SafeHtml;
          }
          return res;
        }),
      );
  }
}
