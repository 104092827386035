import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ROUTES, HttpResponse } from 'src/app/shared';
import { CampaignSchema } from '../../manage-activity/models/activity.interface';
import { LikedUserSchema } from '../../liked-people/interfaces/liked-people.interface';

@Injectable({
  providedIn: 'root',
})
export class ManageProjectService {
  constructor(private http: HttpClient) {}

  public getAllProjects(
    page: number,
    limit: number,
  ): Observable<HttpResponse<{ data: any[]; count: 0 }>> {
    let params = new HttpParams();
    params = params.append('page', page || 1);
    params = params.append('limit', limit || 10);
    return this.http.get<HttpResponse<{ data: any[]; count: 0 }>>(
      API_ROUTES.PROJECTS.SELF,
      { params: params },
    );
  }

  public httpProjectDetail(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${API_ROUTES.PROJECTS.GET}/${id}`);
  }

  public httpAddProject(
    requestPayload: any,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(
      API_ROUTES.PROJECTS.POST,
      requestPayload,
    );
  }

  public httpUpdateProject(
    id: any,
    requestPayload: any,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(
      `${API_ROUTES.PROJECTS.GET}/${id}`,
      requestPayload,
    );
  }

  public httpDeleteProject(
    id: any,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(
      `${API_ROUTES.PROJECTS.SELF}/${id}`,
    );
  }

  public httpGetCampaigns(): Observable<
    HttpResponse<{ data: CampaignSchema[]; count: number }>
  > {
    return this.http.get<HttpResponse<{ data: CampaignSchema[]; count: number }>>(
      API_ROUTES.CAMPAIGNS.GET,
    );
  }

  public getFollowed(id: string): Observable<HttpResponse<{code:string}>> {
    return this.http.get<HttpResponse<{code:string}>>(
      `${API_ROUTES.PROJECTS.GET}/${id}/follow/is_followed`,
    );
  }

  updateProjectStatus(id: any, payloadBody: { status: string, reason: string }): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(
      `${API_ROUTES.PROJECTS.GET}/isPublished/${id}`, payloadBody,
    );
  }
    
  public httpFollowProfile(
    isFollowed: boolean | string,
    profileId: string,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(
      `${API_ROUTES.PROJECTS.GET}/${profileId}/follow/is_followed`, { is_followed: isFollowed ? 'true' : 'false' },
    );
  }

  public httpLikeDislikePeople(
    payload: LikedUserSchema,
    profileId: string,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(
      `${API_ROUTES.PROJECTS.GET}/${profileId}/is_liked_disliked`,
      payload,
    );
  }

  public httpProjectComments(id: string, self?: string): Observable<HttpResponse<any>> {
    const selfCheck = self ? `?self=${self}` : '';
    return this.http.get<HttpResponse<any>>(`${API_ROUTES.PROJECTS.POST}/${id}/comments${selfCheck}`);
  }

  public httpPostComment(id: string, payload: FormData): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(`${API_ROUTES.PROJECTS.GET}/${id}/comments`,payload);
  }

  public httpPostSubComment(id: string, commentId: string, payload: FormData): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(`${API_ROUTES.PROJECTS.GET}/${id}/comments/${commentId}/reply`,payload);
  }

  public httpDeleteComment(id: string, commentId: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(`${API_ROUTES.PROJECTS.GET}/${id}/comments/${commentId}`);
  }

  public httpSubDeleteComment(id: string, commentId: string, replyId: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(`${API_ROUTES.PROJECTS.GET}/${id}/comments/${commentId}/replies/${replyId}`);
  }

  public httpProjectSubComments(id: string, commentId: string, self?: string): Observable<HttpResponse<any>> {
    const selfCheck = self ? `?self=${self}` : '';
    return this.http.get<HttpResponse<any>>(`${API_ROUTES.PROJECTS.POST}/${id}/comments/${commentId}/replies${selfCheck}`);
  }

  public httpLikeDislikeComment(projectId: string, commentId: string, payload: any
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(
      `${API_ROUTES.PROJECTS.GET}/${projectId}/comments/${commentId}/like-dislike`, payload);
  }

  public httpLikeDislikeSubComment(projectId: string, commentId: string, replyId: string, payload: any
    ): Observable<HttpResponse<{ code: string }>> {
      return this.http.post<HttpResponse<{ code: string }>>(
        `${API_ROUTES.PROJECTS.GET}/${projectId}/comments/${commentId}/replies/${replyId}/like-dislike`, payload);
    }

  public httpCommentStatus(projectId: string, commentId: string, payload: any
    ): Observable<HttpResponse<{ code: string }>> {
      return this.http.patch<HttpResponse<{ code: string }>>(
      `${API_ROUTES.PROJECTS.GET}/${projectId}/comments/${commentId}/status`, payload);
    }

  public httpReplyStatus(projectId: string, commentId: string, replyId: string, payload: any
    ): Observable<HttpResponse<{ code: string }>> {
      return this.http.patch<HttpResponse<{ code: string }>>(
      `${API_ROUTES.PROJECTS.GET}/${projectId}/comments/${commentId}/replies/${replyId}/status`, payload);
    }
}
