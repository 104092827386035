import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Validator } from 'src/app/core/custom-validators/custom-validations';
import {
  CanContainOnlyAlphabets, CoreService, CountrySchema, CustomMessageService, EmailRegex, HasLowerCaseLetter,
  HasNumber, HasSpecialChar, HasUpperCaseLetter, InterestSchema, MobileRegex, UserAuthService, UserSignupForm,
} from 'src/app/shared';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { TokenStorageService } from 'src/app/core/services';
import { VerificationEmailComponent } from 'src/app/shared/Modals/verification-email/verification-email.component';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  public signupForm: UserSignupForm;
  private subscription: Subscription = new Subscription();
  public genderArray: Array<string> = ['Male', 'Female', 'Others'];
  public profileTypeArray: Array<string> = ['Public', 'Private'];
  public countryList: CountrySchema[] = [];
  public interestList: InterestSchema[] = [];
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;

  constructor(private fb: FormBuilder, private activeModal: NgbActiveModal, private userAuthService: UserAuthService,
    private toasterService: CustomMessageService, private authService: SocialAuthService, public coreService: CoreService,
    private tokenStorageService: TokenStorageService, private modalService: NgbModal) {
    this.signupForm = this.fb.group({
      name: ['', [
        Validators.required, Validators.maxLength(100), Validators.minLength(3), Validator.noWhiteSpaceValidator,
        Validator.patternValidator(CanContainOnlyAlphabets, { canContainOnlyAlphabets: true }),
      ]],
      city: ['', Validator.patternValidator(CanContainOnlyAlphabets, { canContainOnlyAlphabets: true })],
      country_id: ['', Validators.required],
      gender: [''],
      profile_type: ['public', Validators.required],
      userInterests: [[], Validators.required],
      email: ['', [Validators.required, Validators.pattern(EmailRegex), Validators.maxLength(250), Validator.noWhiteSpaceValidator]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15),
      Validator.patternValidator(HasUpperCaseLetter, { hasCapitalCase: true }),
      Validator.patternValidator(HasLowerCaseLetter, { hasSmallCase: true }),
      Validator.patternValidator(HasSpecialChar, { hasSpecialCharacters: true }),
      Validator.patternValidator(HasNumber, { hasNumber: true })],
      ],
      confirm_password: ['', [Validators.required]],
      contact: ['', [Validators.pattern(MobileRegex)]],
    }) as UserSignupForm;
  }

  ngOnInit(): void {
    this.ssoLogin();
    this.subscription.add(
      this.coreService.interests$.subscribe((ele) => { this.interestList = ele; this.clearCheckbox(); }),
    );
  }
  clearCheckbox() { this.interestList.forEach((ele: any) => { ele.checked = false; }); }

  public submitForm() {
    if (this.signupForm.valid && this.f['password'].value == this.f['confirm_password'].value) {
      let body = { ...this.signupForm.value };
      if (!this.f['gender'].value) { delete body.gender; }
      if (!this.f['city'].value) { delete body.city; }
      if (!this.f['contact'].value) { delete body.contact; }

      this.subscription.add(
        this.userAuthService.httpUserSignup(body).subscribe({
          next: ({ data }) => {
            // this.toasterService.show("Please verify your email to login we've sent you an email verfication link.", 'success');
            this.close();
            setTimeout(() => {
              const modalRef = this.modalService.open(VerificationEmailComponent, { backdrop: true, size: 'lg' });
              this.subscription.add(
                modalRef.closed.subscribe((res) => {
                  if (res && res == 'LOG_IN') {
                    this.openLoginModal();
                  }
                }),
              );
            }, 100);
          },
        }),
      );
    } else { this.signupForm.markAllAsTouched(); }
  }

  public openLoginModal() {
    const modalRef = this.modalService.open(LoginComponent, {
      backdrop: true,
      size: 'md', 
    });
  }

  public ssoLogin() {
    this.subscription.add(
      this.authService.authState.subscribe({
        next: (res) => {
          if (res && res.idToken) { this.googleLogin(res.idToken); }
        }, error: (err) => { },
      }),
    );
  }

  private googleLogin(token: string) {
    const body = { token: token };
    if (body) {
      this.subscription.add(
        this.userAuthService.ssoLogin(body).subscribe({
          next: ({ data }) => {
            if (data && data.accessToken) {
              this.tokenStorageService.setTokenSession = data.accessToken;
              this.tokenStorageService.loginType = 'SSO';
            }
            this.toasterService.show('Successfully logged in', 'success');
            this.close();
          },
        }),
      );
    } else { this.toasterService.show('Unable to authentication with google', 'error'); }
  }

  public setGenderValue(value: string) { this.f['gender'].setValue(value || ''); }

  public setTypeProfileValue() {
    const value = this.f['profile_type'].value && this.f['profile_type'].value == 'public' ? 'private' : 'public';
    this.f['profile_type'].setValue(value || 'private');
  }

  public selectedInterestList(event: any[]) {
    this.f['userInterests'].setValue(event);
    this.f['userInterests'].markAsTouched();
  }

  public get f(): { [key: string]: AbstractControl } { return this.signupForm.controls; }

  public renderLoginModal() { this.activeModal.close('LOGIN'); }

  public close() { this.activeModal.close(); }
  passwordToggle() { this.showPassword = !this.showPassword; }
  confirmPasswordToggle() { this.showConfirmPassword = !this.showConfirmPassword; }
}
