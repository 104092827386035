<ng-container>
  <div class="abt-page py-4">
    <div class="container">
      <div class="minh-100-custom">
        <div class="row">
          <div class="col-12 col-md-12">
            <div class="text-center mb-3">
              <h2 class="fw-500 fs-1">Privacy Policy</h2>
            </div>
            <div
              class="card shadow-lg-custom p-4 border-0 rounded-4 minh-100-custom"
            >
              <div class="row">
                <div class="col-12 col-md-12 mb-3">
                  <h1 class="fs-3 mb-0">Privacy Notice</h1>
                </div>
                <div class="col-12 col-md-12">
                  <div>
                    <div>
                      <b
                        style="
                          font-family: Arial;
                          font-size: medium;
                          background-color: transparent;
                          color: var(--body-text);
                          text-align: var(--bs-body-text-align);
                        "
                        >Last update: September 2023</b
                      ><br />
                    </div>
                    <div>
                      <span
                        style="
                          font-family: Arial;
                          font-size: medium;
                          background-color: transparent;
                          color: var(--body-text);
                          font-weight: var(--bs-body-font-weight);
                          text-align: var(--bs-body-text-align);
                        "
                        ><br
                      /></span>
                    </div>
                    <div>
                      <span
                        style="
                          font-family: Arial;
                          font-size: medium;
                          background-color: transparent;
                          color: var(--body-text);
                          font-weight: var(--bs-body-font-weight);
                          text-align: var(--bs-body-text-align);
                        "
                        >This Privacy Notice applies to the website found at
                        youthactionplatform.org, including all information and
                        materials contained on it (“the Youth Action Platform”).
                        This Website is managed by Plan Limited, a wholly-owned
                        subsidiary of Plan International Inc. (“Plan
                        Ltd”).</span
                      ><br />
                    </div>
                    <div><br /></div>
                    <div>
                      <b>Our Privacy Notice</b>
                    </div>
                    <div>
                      Plan Ltd is committed to ensuring the privacy of all users
                      of the Website and will comply with data protection law
                      when processing personal data. Personal data is any
                      information that can be used to identify a living
                      individual.
                    </div>
                    <div>
                      <span
                        style="
                          font-family: Arial;
                          font-size: medium;
                          background-color: transparent;
                          color: var(--body-text);
                          font-weight: var(--bs-body-font-weight);
                          text-align: var(--bs-body-text-align);
                        "
                        >For all data protection queries and concerns -
                        Support@YouthActionPlatform.org</span
                      ><br />
                    </div>
                    <div></div>
                    <div>
                      <div>
                        This Privacy Notice governs all pages on the domain
                        youthactionplatform.org and sets out how Plan Ltd
                        collects and processes personal data via the Website. It
                        does not apply to pages hosted by other organisations,
                        or third-party sites. The Website may be linked to the
                        websites of external parties, but those other sites may
                        have their own privacy policies which apply to them.
                      </div>
                      <div>
                        <span
                          style="
                            background-color: transparent;
                            color: var(--body-text);
                            font-weight: var(--bs-body-font-weight);
                            text-align: var(--bs-body-text-align);
                          "
                          >It is important that you read and understand this
                          Privacy Notice so that you are aware of how and why we
                          might collect and process your personal information,
                          and what your rights are under the data protection
                          legislation. By using the Youth Action Platform, you
                          consent to the terms of this Privacy Notice. Plan Ltd
                          may change the terms of this Privacy Notice from time
                          to time and, if such changes are made, we will notify
                          you of those changes by contacting you if appropriate
                          or by publishing the updated Privacy Notice on our
                          website. Your continued use of the Youth Action
                          Platform will be on the terms of this Privacy Notice
                          as amended from time to time.</span
                        ><br />
                      </div>
                      <div>
                        <span
                          style="
                            background-color: transparent;
                            color: var(--body-text);
                            font-weight: var(--bs-body-font-weight);
                            text-align: var(--bs-body-text-align);
                          "
                          ><br
                        /></span>
                      </div>
                      <div>
                        <span
                          style="
                            background-color: transparent;
                            text-align: var(--bs-body-text-align);
                          "
                          ><div>
                            <b>What personal data we collect and store</b>
                          </div>
                          <div>
                            The type and amount of information about you that we
                            collect and store depends on how you use the Youth
                            Action Platform. You can access most of the pages on
                            the Website without telling us who you are and
                            without revealing any personal information. Plan Ltd
                            collects anonymous logs during user visits to the
                            Youth Action Platform to create aggregate statistics
                            about how the website is used,.to improve the Youth
                            Action Platform and to provide you with a better
                            user experience. For example, we use your
                            information to:
                          </div>
                          <div>
                            •<span style="white-space: pre"> </span> Remember
                            your preferences and settings
                          </div>
                          <div>
                            •<span style="white-space: pre"> </span> Track your
                            browsing activity
                          </div>
                          <div>
                            •<span style="white-space: pre"> </span> Improve the
                            performance of the Platform
                          </div>
                          <div>
                            <div>
                              We only collect the following personal information
                              about you via the Youth Action Platform if you
                              choose to provide it when you create an account:
                            </div>
                            <div>
                              •<span style="white-space: pre"> </span> Your name
                            </div>
                            <div>
                              •<span style="white-space: pre"> </span> Your email
                              address
                            </div>
                            <div>
                              •<span style="white-space: pre"> </span> Your
                              password
                            </div>
                            <div>
                              The Platform may also collect additional personal
                              information from you, such as your contact number,
                              date of birth, gender, and location, if you choose
                              to provide it.
                            </div>
                            <div>
                              Or when you email us via the “Contact Us”
                              facility.
                            </div>
                            <div><br /></div>
                            <div>
                              <div>
                                <b>Why we process your personal data</b>
                              </div>
                              <div>
                                Plan Ltd will only process your personal data in
                                connection with the purpose for which you
                                provided it. In other words, in order to send
                                you information and / or email updates about the
                                work we are doing, or to show your support for a
                                particular campaign. In this way, Plan Ltd’s
                                processing of your personal data is based on
                                your consent.&nbsp;
                              </div>
                              <div><br /></div>
                              <div>
                                <b>Who your personal data is shared with</b>
                              </div>
                              <div>
                                Your personal data will be shared internally
                                within Plan Ltd so that we can fulfil the
                                purpose for which you provided it. When you have
                                participated in one of our projects or
                                campaigns, we may share your name and details of
                                the country where you live with the
                                organisation(s) which is the target(s) of the
                                project or campaign. Where necessary, we will
                                share your information with our service
                                providers in order to allow them to deliver
                                services to Plan Ltd, for example, if we
                                contract with a service provider to host the
                                Youth Action Platform. When we have your
                                consent, we will share your personal information
                                with Plan International offices if they request
                                details of project participants in their
                                territory. Otherwise, we shall only share your
                                personal information if we are required to
                                disclose it by law. Plan Ltd will never sell the
                                personal data that you provide.&nbsp;
                              </div>
                              <div><br /></div>
                              <div>
                                <b
                                  >Opting out of receiving messages from Plan
                                  Ltd</b
                                >
                              </div>
                              <div>
                                If you have created an account on the Youth
                                Action Platform, we will hold your personal
                                information for as long as your account remains
                                active. Any e-mail we send you will enable you
                                to opt out of receiving further e-mails from us.
                                If you choose to unsubscribe from receiving
                                further emails, we will immediately stop sending
                                you any communications and we will remove your
                                personal information from our system within six
                                months. You can also request that we remove you
                                from our mailing lists by contacting us at
                                Support@YouthActionPlatform.org.
                              </div>
                            </div>
                            <div><br /></div>
                            <div>
                              <div>
                                <b>How your personal data is protected</b>
                              </div>
                              <div>
                                Plan Ltd takes the security of your personal
                                data seriously. There are internal policies,
                                procedures and controls in place to try and
                                ensure that your personal data is not lost,
                                accidentally destroyed, misused or disclosed,
                                and is only accessed by its employees in the
                                performance of their duties.
                              </div>
                              <div>
                                Where Plan Ltd engages third parties to process
                                personal data on its behalf, they do so only the
                                basis of written instructions, and under a duty
                                of confidentiality. Third parties are also
                                obliged to implement appropriate technical and
                                organisational measures which are compliant with
                                data protection legislation to ensure the
                                security of personal data.&nbsp;
                              </div>
                              <div><br /></div>
                              <div><b>What your rights are&nbsp;</b></div>
                              <div>
                                You have the right to access your information,
                                to correct any inaccuracies in your information,
                                and to request that your information be deleted.
                                You can exercise these rights by contacting us
                                at Support@YouthActionPlatform.org&nbsp;&nbsp;
                              </div>
                              <div><br /></div>
                              <div><b>Complaint resolution</b></div>
                              <div>
                                Plan Ltd will strive to resolve any complaints
                                relating to its processing of personal data
                                promptly and fairly. Plan Ltd will cooperate
                                with data protection authorities and other
                                regulators, agencies, and tribunals involved in
                                resolving such complaints. In the first
                                instance, please raise your concerns to
                                Support@YouthActionPlatform.org
                              </div>
                            </div>
                          </div></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
