<div class="dropdown profile-dropdown ms-2" *ngIf="countryList.length">
    <button
      class="btn btn-outline-primary rounded-pill px-3 py-2 ms-auto fw-600 dropdown-toggle dropdown-toggle-custom"
      type="button" id="languageName" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span appCountryFlag [countryId]="getCountryById(selectedLanguage?.country)" *ngIf="!loading"></span>
      <span>{{ selectedLanguage ? selectedLanguage.name : '' }}</span>
    </button>
    <div class="dropdown-menu shadow-lg-custom" aria-labelledby="languageName">
      <a (click)="changeLanguage(lang)" class="dropdown-item text-decoration-none fs-14" 
      *ngFor="let lang of languageDropdown">
        <span appCountryFlag [countryId]="getCountryById(lang?.country)"></span>{{lang.name}}
      </a>
    </div>
</div>