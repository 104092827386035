import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cookie-consent',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {
  showConsent: boolean = false;

  constructor(private cookieService: CookieService){ }
 
  ngOnInit(){
    const cookie = this.cookieService.get('cookie-consent');
    if(cookie === 'true'){ 
      this.showConsent = false;
    } else {
      this.showConsent = true;
    }
  }

  acceptConsent() {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    this.cookieService.set('cookie-consent', 'true', expirationDate, '/');
    this.showConsent = false;
  }
}
