import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselComponent, SlickCarouselModule } from 'ngx-slick-carousel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-image-slider-modal',
  standalone: true,
  imports: [CommonModule, SlickCarouselModule ],
  templateUrl: './image-slider-modal.component.html',
  styleUrls: ['./image-slider-modal.component.scss']
})
export class ImageSliderModalComponent implements AfterViewInit{
  @ViewChild('slickModal') slickModal!: SlickCarouselComponent;
  public slides: any;
  public currentIndex: any;

  public slideConfig = { 
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  patchData(data?: any, index?: number): void {
    this.slides = data
    this.currentIndex = index
  }

  ngAfterViewInit(): void {
    this.slickModal?.slickGoTo(this.currentIndex);  
  }
}
