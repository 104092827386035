<ng-container>
    <div class="abt-page py-4">
        <div class="container">
            <div class="minh-100-custom">
                <div class="row">
                    <div class="col-12 col-md-12">
                        <div class="text-center mb-3">
                            <h2 class="fw-500 fs-1">Delete Account</h2>
                        </div>
                        <div class="card shadow-lg-custom p-4 border-grey text-left rounded-4 my-3 border-0">
                            <div>
                                <div class="mb-2">
                                  <h6>Step 1</h6>
                                  Log in to the application <br />
                                </div>
                                <div class="mb-2">
                                  <h6>Step 2</h6>
                        
                                  Navigate to the side drawer menu
                                </div>
                        
                                <div class="mb-2">
                                  <h6>Step 3</h6>
                        
                                  Click on My Profile
                                </div>
                        
                                <div class="mb-2">
                                  <h6>Step 4</h6>
                        
                                  At the bottom of the page, click on "Delete Account". A confirmation popup will appear
                                </div>
                        
                                <div class="mb-2">
                                  <h6>Step 5</h6>
                        
                                  Click on Yes
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>