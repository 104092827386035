<!-- Modal -->
<div class="modal-content border-0">
  <div class="modal-header">
    <h2 class="fs-4 mb-0 text-start">{{heading}}</h2>
    <button type="button" (click)="closemodal()" class="btn-close"></button>
  </div>
  <div class="modal-body border-0 py-3 px-4  global-scroll">
    <div class="d-inline-block" *ngFor="let country of countryData">
      <span class="flag text-grey-2 fs-14 rounded-pill px-4 border-grey mb-2 d-inline-block">
        <span appCountryFlag [countryId]="country?.country_id"></span>
        {{ getCountryName(country?.country_id) }}
      </span>
    </div>
    <div *ngIf="body"> <p class="text-grey-2 text-center">{{body}}</p></div>
  </div>
  <div class="modal-footer border-0 justify-content-center mt-0">
    <button
      type="button"
      (click)="closemodal()"
      class="btn btn-outline-primary"
    >
      Close
    </button>
  </div>
</div>

<!-- <div class="modal-content border-0">
  <div class="modal-header justify-content-center">
    <h2 class="fs-4">{{heading}}</h2>
    <button type="button" (click)="closemodal()" class="btn-close"></button>
  </div>
  <div class="modal-body border-0 py-3 px-4">
    <div class="d-inline-block text-center me-3" *ngFor="let country of countryData">
        <div appCountryFlag [countryId]="country?.country_id" style="width: 61px; height:47px; border-radius: 50%;"></div>
        
      <div class="fw-700">{{ getCountryName(country?.country_id) }}</div>
    </div>
    <div *ngIf="body" class="fs-18 text-grey-2 text-center">{{body}}</div>
  </div>
  <div class="modal-footer border-0 justify-content-center mt-0">
    <button
      type="button"
      (click)="closemodal()"
      class="btn btn-outline-primary"
    >
      Close
    </button>
  </div>
</div> -->
