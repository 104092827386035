import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { CountryFlagModule } from '../../country-flag.module';

@Component({
  selector: 'app-multi-select-dropdown',
  standalone: true,
  imports: [CommonModule, FormsModule, CountryFlagModule],
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectDropdownComponent),
      multi: true
    }
  ]
})
export class MultiSelectDropdownComponent {
  list: any = [];
  temp_list: any = [];
  // @Input() list!: any[];
  @Input() asyncList!: Observable<any[]>;
  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();
  @Input("items") set items(value: any) {
    this.list = value;
    this.temp_list = value;
  }
  @Input() checkedList: any[];
  public currentSelected: {} | undefined;
  public showDropDown: boolean = false;
  @Input() selectedList: any = [];
  @Input() placeholder: string = ''
  public allSelected: boolean = false;
  keyword = "";
  filterList: any = [];

  constructor() {
    this.allSelected = false;
    this.checkedList = [];
    this.selectedList = [];
    this.currentSelected = [];
    this.filterList = this.list;
    this.placeholder = this.placeholder ? this.placeholder : 'Select Countries';
  }

  public getSelectedValue(status: Boolean, value: any, selectAllFlag?: boolean) {
    if (status) {
      if (
        !this.checkedList.includes(value.name) &&
        !this.selectedList.includes(value.id)
      ) {
        this.checkedList.push(value.name);
        this.selectedList.push(value.id);
      }
    } else {
      this.allSelected = false;
      var index = this.checkedList.indexOf(value.name);
      this.selectedList.splice(index, 1);
      this.checkedList.splice(index, 1);
    }
    this.currentSelected = { checked: status, value: value };
    this.shareCheckedlist();
    this.shareIndividualStatus();
    // this.allSelected = this.checkedList.length === this.list.length;
    this.allSelected = selectAllFlag ? this.allSelected : (this.checkedList.length === this.list.length);
  }

  search(e: any) {
    const val = e.toLowerCase();
    const temp = this.temp_list.filter((x: any) => {
      if (x['name'].toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.list = temp;
    this.allSelected = (this.checkedList.length === this.list.length);
  }

  public selectAllToggle() {
    this.list?.forEach((ele: any) => {
      this.checkedList = this.allSelected ? this.checkedList : [];
      ele.checked = this.allSelected;
      this.getSelectedValue(this.allSelected, ele, true);
    });
    this.allSelected = (this.checkedList.length === this.list.length);
  }

  public shareCheckedlist() {
    this.shareCheckedList.emit(this.selectedList);
  }

  public shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }
}
