import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ROUTES, HttpResponse } from 'src/app/shared';
import { Article, ArticleListSchema } from '../models/article';
import { LikedUserSchema } from '../../liked-people/interfaces/liked-people.interface';

@Injectable({
  providedIn: 'root'
})
export class ManageArticleService {

  constructor(private http: HttpClient) { }
  public getAllArticles(
    page: number,
    limit: number,
  ): Observable<HttpResponse<{ data: Article[]; count: 0 }>> {
    let params = new HttpParams();
    params = params.append('page', page || 1);
    params = params.append('limit', limit || 10);
    return this.http.get<HttpResponse<{ data: Article[]; count: 0 }>>(
      API_ROUTES.ARTICLES.GET_All,
      { params: params },
    );
  }

  public getArticleById(id: string): Observable<HttpResponse<ArticleListSchema>> {
    return this.http.get<HttpResponse<ArticleListSchema>>(
      `${API_ROUTES.ARTICLES.GET}/${id}`,
    );
  }

  public getFollowed(id: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.get<HttpResponse<{ code: string }>>(
      `${API_ROUTES.ARTICLES.GET}/${id}/follow/is_followed`,
    );
  }

  public httpAddArticle(
    requestPayload: any,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(
      API_ROUTES.ARTICLES.POST,
      requestPayload,
    );
  }

  public updateArticle(
    id: any,
    requestPayload: any,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(
      `${API_ROUTES.ARTICLES.GET_BY_ID}/${id}`,
      requestPayload,
    );
  }

  public deleteArticle(id: any): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(
      `${API_ROUTES.ARTICLES.GET_All}/${id}`,
    );
  }

  public postReportArticleById(
    id: string,
    data: string,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(
      `${API_ROUTES.ARTICLES.GET_BY_ID}/${id}/report`,
      data,
    );
  }

  updateArticleStatus(id: any, payloadBody: { status: string, reason: string }): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(
      `${API_ROUTES.ARTICLES.GET_BY_ID}/isPublished/${id}`, payloadBody,
    );
  }

  public httpFollowProfile(
    isFollowed: boolean | string,
    profileId: string,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(
      `${API_ROUTES.ARTICLES.GET}/${profileId}/follow/is_followed`, { is_followed: isFollowed ? 'true' : 'false' },
    );
  }

  public httpLikeDislikePeople(
    payload: LikedUserSchema,
    profileId: string,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(
      `${API_ROUTES.ARTICLES.GET}/${profileId}/is_liked_disliked`,
      payload,
    );
  }

  public httpComments(id: string, self?: string): Observable<HttpResponse<any>> {
    const selfCheck = self ? `?self=${self}` : '';
    return this.http.get<HttpResponse<any>>(`${API_ROUTES.ARTICLES.POST}/${id}/comments${selfCheck}`);
  }

  public httpPostComment(id: string, payload: FormData): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(`${API_ROUTES.ARTICLES.GET}/${id}/comments`,payload);
  }

  public httpPostSubComment(id: string, commentId: string, payload: FormData): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(`${API_ROUTES.ARTICLES.GET}/${id}/comments/${commentId}/reply`,payload);
  }

  public httpProjectSubComments(id: string, commentId: string, self?: string): Observable<HttpResponse<any>> {
    const selfCheck = self ? `?self=${self}` : '';
    return this.http.get<HttpResponse<any>>(`${API_ROUTES.ARTICLES.POST}/${id}/comments/${commentId}/replies${selfCheck}`);
  }

  public httpDeleteComment(id: string, commentId: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(`${API_ROUTES.ARTICLES.GET}/${id}/comments/${commentId}`);
  }

  public httpSubDeleteComment(id: string, commentId: string, replyId: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(`${API_ROUTES.ARTICLES.GET}/${id}/comments/${commentId}/replies/${replyId}`);
  }

  public httpLikeDislikeComment(projectId: string, commentId: string, payload: any
    ): Observable<HttpResponse<{ code: string }>> {
      return this.http.post<HttpResponse<{ code: string }>>(
        `${API_ROUTES.ARTICLES.GET}/${projectId}/comments/${commentId}/like-dislike`, payload);
    }
  
  public httpLikeDislikeSubComment(projectId: string, commentId: string, replyId: string, payload: any
    ): Observable<HttpResponse<{ code: string }>> {
      return this.http.post<HttpResponse<{ code: string }>>(
        `${API_ROUTES.ARTICLES.GET}/${projectId}/comments/${commentId}/replies/${replyId}/like-dislike`,payload);
    }
  
  public httpCommentStatus(projectId: string, commentId: string, payload: any
      ): Observable<HttpResponse<{ code: string }>> {
        return this.http.patch<HttpResponse<{ code: string }>>(
        `${API_ROUTES.ARTICLES.GET}/${projectId}/comments/${commentId}/status`, payload);
      }
  
  public httpReplyStatus(projectId: string, commentId: string, replyId: string, payload: any
      ): Observable<HttpResponse<{ code: string }>> {
        return this.http.patch<HttpResponse<{ code: string }>>(
        `${API_ROUTES.ARTICLES.GET}/${projectId}/comments/${commentId}/replies/${replyId}/status`, payload);
      }
}

