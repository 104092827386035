import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../services/common.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
})
export class ReportModalComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public reportsList: Array<{ id: string; name: string }> = [];
  public reportName!: string;
  public AdditionalInput!: string;
  constructor(
    private activemodal: NgbActiveModal,
    private commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.fetchReportList();
  }

  fetchReportList() {
    this.subscription.add(
      this.commonService.httpGetReportList().subscribe({
        next: ({ data }) => {
          this.reportsList = data && data.data ? data.data : [];
        },
      }),
    );
  }

  closemodal() {
    this.activemodal.close();
  }

  selectReportName(item: string) {
    this.reportName = item;
  }

  submit(AdditionalInput: string) {
    const obj = {
      report_id: this.reportName,
      additional_info: AdditionalInput,
    };
    this.activemodal.close(obj);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
