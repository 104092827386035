import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LightboxModule } from 'ngx-lightbox';
import { environment } from 'src/environment/environment';
import { SlickCarouselComponent, SlickCarouselModule } from 'ngx-slick-carousel';
import { Subscription } from 'rxjs';
import { CommonService, ExploreService, CustomMessageService, HeaderService, MainService, CoreService } from '../../services';
import { RouterModule } from '@angular/router';
import { ProfileSchema } from '../../interfaces';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportModalComponent } from '../../Modals';
import { TokenStorageService } from 'src/app/core/services';
import { ManageProjectService } from 'src/app/feature/modules/post-auth-pages/modules/manage-projects/services';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageActivityService } from 'src/app/feature/modules/post-auth-pages/modules/manage-activity/services/manage-activity.service';
import { ManageArticleService } from 'src/app/feature/modules/post-auth-pages/modules/manage-article/manage-article/manage-article.service';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { CommentTranslationService } from '../../services/comment-translation.service';
import { TranslateModule } from '@ngx-translate/core';
import { CountryFlagModule } from '../../country-flag.module';
import { LimitToPipe } from '../../pipes';
import { CountryViewComponent } from '../../Modals/country-view/country-view.component';
import { ImageSliderModalComponent } from '../../Modals/image-slider-modal/image-slider-modal.component';
import { Meta } from '@angular/platform-browser';
import { CensorPipe } from '../../pipes/censor.pipe';

declare var FB: any;

@Component({
  selector: 'app-activity-detail-ui',
  standalone: true,
  imports: [CommonModule, RouterModule, LightboxModule, SlickCarouselModule, NgbModule, ReactiveFormsModule, PickerModule, TranslateModule, CountryFlagModule, LimitToPipe, CensorPipe],
  templateUrl: './activity-detail-ui.component.html',
  styleUrls: ['./activity-detail-ui.component.scss'],
})
export class ActivityDetailUiComponent implements OnInit, OnChanges {
  @Input() public id: string = '';
  @Input() public detail: any;
  @Input() public pageData!: { title: string; prev_path: string };
  @Input() public followdata: any;
  @Input() public flag!: string;
  @Output() clickEvent: EventEmitter<{ event: string; data?: any }> = new EventEmitter<{ event: string; data?: any }>();
  public readonly imageUrl: string = environment.imageUrl;
  public ProfileData!: ProfileSchema;
  public data: any;
  public slides: Array<string> = [];
  public slideConfig = { 
    slidesToShow: 1,
    slidesToScroll: 1
  };
  public modalRef: any;
  public isFollowed: boolean = false;
  public isLiked: boolean = false;
  public isDisliked: boolean = false;
  public _album: any[] = [];
  public sliderImage: string = '';
  public selfUser: boolean = false;
  public comments: any = [];
  public totalComments!: number;
  public pendingComments: number = 0;
  public commentCtrl: FormControl = new FormControl();
  public showSubComment: boolean = false;
  public selectedIndex: any;
  public totalSubComments!: number;
  public subComments: any[] = []
  public subCommentCtrl: FormControl = new FormControl();
  public commentId: string = '';
  public userData!: ProfileSchema;
  public selfComment: boolean = false;
  private subscription: Subscription = new Subscription();
  public selfProfile: any;

  @ViewChild("container") container: ElementRef<HTMLElement> | undefined;
  showEmojiPicker = false;
  orderedCategories: any[] = [
    'frequent',
    'search',
    'custom',
    'people',
    'nature',
    'foods',
    'activity',
    'places',
    'objects',
    'symbols',
  ];

  @ViewChild("replyContainer") replyContainer: ElementRef<HTMLElement> | undefined;
  showReplyEmojiPicker = false;

  translatedComments: string[] = [];
  translatedReplyComments: string[] = [];
  public countryList: Array<any> = [];
  public  selectedThumbnailIndex: number = 0;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent | undefined;
  public followerCount: number = 0;

  public sharingUrl: string = environment.sharingUrl;
  public socialShareUrl: string = '';
  public restrictedWords: any;

  constructor(private tokenStorageService: TokenStorageService, private modalService: NgbModal, private headerService: HeaderService, public exploreService: ExploreService, public mainService: MainService, private commonService: CommonService, private projectService: ManageProjectService, private toasterService: CustomMessageService, private activityService: ManageActivityService, private articleService: ManageArticleService, private commentTranslateService: CommentTranslationService, private coreService: CoreService, public meta: Meta) { }

  ngOnInit(): void {
    this.getCountryList();
    this.fetchProjectComments();

    this.selfUser = this.mainService.getUserDetail.detail?.id === this.detail.data.user_id;
    this.selfProfile = this.mainService.getUserDetail.detail;
    if (this.selfProfile) { this.selfProfile.bgColor = this.mainService.getRandomColor(); }

    if (this.selfUser) {
      this.ProfileData = this.mainService.getUserDetail.detail;
      if (this.ProfileData) { this.ProfileData.bgColor = this.mainService.getRandomColor(); }
    } else {
      this.ProfileData = this.data && this.data?.user ? this.data.user : '';
      if (this.ProfileData) { this.ProfileData.bgColor = this.mainService.getRandomColor(); }
    }

    this.userData = this.mainService.getUserDetail.detail;

    this.setActivityViewCount();

    this.subscription.add(
      this.coreService.restrictedWords$.subscribe((data) => { 
        this.restrictedWords = data ? data : [];
       }),
    );
  }

  ngOnChanges() {
    if (this.detail) {
      this.slides = [];
      this.data! = this.detail.data;
      if (this.flag == 'campaigns') {
        this.followerCount = this.detail?.campaignFollowersCount;
        this.socialShareUrl = this.sharingUrl +'/campaigns/'+ this.detail?.data?.id;
        if (this.detail.campaign_is_liked != null) {
          this.isLiked = this.detail.campaign_is_liked ? true : false;
          this.isDisliked = this.detail.campaign_is_liked ? false : true;
        }
        else { this.isLiked = false; this.isDisliked = false; }
      } else if (this.flag == 'projects') {
        this.followerCount = this.detail?.projectFollowersCount;
        this.socialShareUrl = this.sharingUrl +'/projects/'+ this.detail?.data?.id;
        if (this.detail.project_is_liked != null) {
          this.isLiked = this.detail.project_is_liked ? true : false;
          this.isDisliked = this.detail.project_is_liked ? false : true;
        }
        else { this.isLiked = false; this.isDisliked = false; }
      } else if (this.flag == 'articles') {
        this.followerCount = this.detail?.articleFollowersCount;
        this.socialShareUrl = this.sharingUrl +'/articles/'+ this.detail?.data?.id;
        if (this.detail.article_is_liked != null) {
          this.isLiked = this.detail.article_is_liked ? true : false;
          this.isDisliked = this.detail.article_is_liked ? false : true;
        }
        else { this.isLiked = false; this.isDisliked = false; }
      }

      if (this.data.resources.length) {
        this.slides = this.data.resources.map((X: any) => `${this.imageUrl}${X.url}`);
      }
      if (this.data.thumbnail_url) { this.slides.unshift(this.imageUrl + this.data.thumbnail_url); }
      this.sliderImage = this.slides[0] ? this.slides[0] : '';
    }
  }

  setActivityViewCount() {
    if (this.flag == 'campaigns' && this.isLoggedIn) { this.exploreService.updateCampaignViewCount(this.data.id).subscribe((next => { })) }
    if (this.flag == 'projects' && this.isLoggedIn) { this.exploreService.updateProjectViewCount(this.data.id).subscribe((next => { })) }
    if (this.flag == 'articles' && this.isLoggedIn) { this.exploreService.updateArticleViewCount(this.data.id).subscribe((next => { })) }
  }

  _clickEvent(flag: string) {
    if (this.isLoggedIn) { this.clickEvent.emit({ event: flag }); }
    else { this.openLoginModal(); }
  }

  postLikeDislike(isLiked: boolean) {
    if (this.isLoggedIn) {
      const profileData = this.mainService.getUserDetail.detail;
      const id = profileData.profile.id;
      const isLike = (isLiked && this.isLiked === true) || (isLiked === false && this.isDisliked === true) ? null : isLiked;
      const body: any = { is_liked: isLike === null ? null : isLike ? 'true' : 'false' };
      this.isLiked = false;
      this.isDisliked = false;
      this.clickEvent.emit({ event: isLiked ? 'LIKE' : 'DISLIKE', data: body });
    }
    else {
      this.openLoginModal();
    }
  }

  openModal() {
    if (this.isLoggedIn) {
      this.modalRef = this.modalService.open(ReportModalComponent, { backdrop: true, size: 'md' });
      this.modalRef.result.then((result: string) => {
        if (result) { this.postReport(result); }
      });
    }
    else { this.openLoginModal(); }
  }

  postReport(data: any) { this.clickEvent.emit({ event: 'REPORT', data: data }); }

  removeSlide() { this.slides.length = this.slides.length - 1; }

  slideImage(image: string) { this.sliderImage = image; }

  safeHtml(html: string) { return this.commonService.safeHTML(html); }

  open(i: any, thumbnail_url: string): void {
    this._album = [];
    const src = thumbnail_url;
    const album = { src: src, thumb: src };
    this._album.push(album);
    this.commonService.zoomImage(this._album, 0);
  }

  goToLink(url: string) {
    if (url.includes('http')) { window.open(url, '_blank'); }
    else { window.open('//' + url, '_blank'); }
  }

  public get isLoggedIn(): boolean {
    if (this.tokenStorageService.getTokenSession && this.mainService.getUserDetail) { return true; } else { return false; }
  }

  private openLoginModal() { return (this.headerService._setRenderModal = 'LOGIN'); }

  public fetchProjectComments() {
    if (this.flag === 'projects') {
      this.subscription.add(
        this.projectService.httpProjectComments(this.id).subscribe({
          next: ({ data }) => {
            this.totalComments = data?.count
            this.comments = data?.data
            
            this.comments.map((ele: any) => {
              if (ele.user && !ele.bgColor) { ele.bgColor = this.mainService.getRandomColor(); }
              if (ele.user_id == this.mainService.getUserDetail.detail?.id) {
                ele.selfComment = true
              } else { ele.selfComment = false }
            })
          },
        }),
      );
    }
    else if (this.flag === 'campaigns') {
      this.subscription.add(
        this.activityService.httpComments(this.id).subscribe({
          next: ({ data }) => {
            this.totalComments = data?.count
            this.comments = data?.data
            this.comments.map((ele: any) => {
              if (ele.user && !ele.bgColor) { ele.bgColor = this.mainService.getRandomColor(); }
              if (ele.user_id == this.mainService.getUserDetail.detail?.id) {
                ele.selfComment = true
              } else { ele.selfComment = false }
            })
          },
        }),
      );
    }
    else if (this.flag === 'articles') {
      this.subscription.add(
        this.articleService.httpComments(this.id).subscribe({
          next: ({ data }) => {
            this.totalComments = data?.count
            this.comments = data?.data
            this.comments.map((ele: any) => {
              if (ele.user && !ele.bgColor) { ele.bgColor = this.mainService.getRandomColor(); }
              if (ele.user_id == this.mainService.getUserDetail.detail?.id) {
                ele.selfComment = true
              } else { ele.selfComment = false }
            })
          },
        }),
      );
    }
    // this.fetchPendingComments();
  }

  postComment(e?:any) {
    e.preventDefault();
    if (this.isLoggedIn) {
      if (this.flag === 'projects') {
        if (this.commentCtrl.value) {
          let formData = new FormData();
          formData.append('comment',this.commentCtrl.value)

          this.subscription.add(
            this.projectService.httpPostComment(this.id, formData).subscribe({
              next: ({ data }) => {
                this.fetchProjectComments();
                this.clearTextInput();
                this.showSubComment = false
              },
            }),
          );
        }
      }
      else if (this.flag === 'campaigns') {
        if (this.commentCtrl.value) {
          let formData = new FormData();
          formData.append('comment',this.commentCtrl.value)

          this.subscription.add(
            this.activityService.httpPostComment(this.id, formData).subscribe({
              next: ({ data }) => {
                this.fetchProjectComments();
                this.clearTextInput();
                this.showSubComment = false
              },
            }),
          );
        }
      }
      else if (this.flag === 'articles') {
        if (this.commentCtrl.value) {
          let formData = new FormData();
          formData.append('comment',this.commentCtrl.value)

          this.subscription.add(
            this.articleService.httpPostComment(this.id, formData).subscribe({
              next: ({ data }) => {
                this.fetchProjectComments();
                this.clearTextInput();
                this.showSubComment = false
              },
            }),
          );
        }
      }
    } else { this.openLoginModal(); }
  }

  postSubComment(e?:any) {
    e.preventDefault();
    if (this.isLoggedIn) {
      if (this.flag === 'projects') {
        if (this.subCommentCtrl.value) {
          let formData = new FormData();
          formData.append('comment',this.subCommentCtrl.value)
          this.subscription.add(
            this.projectService.httpPostSubComment(this.id, this.commentId, formData).subscribe({
              next: ({ data }) => {
                this.fetchProjectComments();
                this.subCommentSection();
                this.clearTextInput();
              },
            }),
          );
        }
      }
      else if (this.flag === 'campaigns') {
        if (this.subCommentCtrl.value) {
          let formData = new FormData();
          formData.append('comment',this.subCommentCtrl.value)
          this.subscription.add(
            this.activityService.httpPostSubComment(this.id, this.commentId, formData).subscribe({
              next: ({ data }) => {
                this.fetchProjectComments();
                this.subCommentSection();
                this.clearTextInput();
              },
            }),
          );
        }
      }
      else if (this.flag === 'articles') {
        if (this.subCommentCtrl.value) {
          let formData = new FormData();
          formData.append('comment',this.subCommentCtrl.value)
          this.subscription.add(
            this.articleService.httpPostSubComment(this.id, this.commentId, formData).subscribe({
              next: ({ data }) => {
                this.fetchProjectComments();
                this.subCommentSection();
                this.clearTextInput();
              },
            }),
          );
        }
      }
    } else { this.openLoginModal(); }
  }

  openSubComment(commentId: string, index: number) {
    this.clearTextInput();
    this.commentId = commentId;
    this.showSubComment = (!this.showSubComment || this.selectedIndex === index) ? !this.showSubComment : this.showSubComment
    this.selectedIndex = index;
    this.subCommentSection();
  }

  subCommentSection() {
    if (this.flag === 'projects') {
      this.subscription.add(
        this.projectService.httpProjectSubComments(this.id, this.commentId).subscribe({
          next: ({ data }) => {
            this.totalSubComments = data?.count
            this.subComments = data?.data
            this.subComments.map((ele: any) => {
              if (ele.user && !ele.bgColor) { ele.bgColor = this.mainService.getRandomColor(); }
              if (ele.user_id == this.mainService.getUserDetail.detail?.id) {
                ele.selfComment = true
              } else { ele.selfComment = false }
            })
          },
        }),
      );
    }
    else if (this.flag === 'campaigns') {
      this.subscription.add(
        this.activityService.httpProjectSubComments(this.id, this.commentId).subscribe({
          next: ({ data }) => {
            this.totalSubComments = data?.count
            this.subComments = data?.data
            this.subComments.map((ele: any) => {
              if (ele.user && !ele.bgColor) { ele.bgColor = this.mainService.getRandomColor(); }
              if (ele.user_id == this.mainService.getUserDetail.detail?.id) {
                ele.selfComment = true
              } else { ele.selfComment = false }
            })
          },
        }),
      );
    }
    else if (this.flag === 'articles') {
      this.subscription.add(
        this.articleService.httpProjectSubComments(this.id, this.commentId).subscribe({
          next: ({ data }) => {
            this.totalSubComments = data?.count
            this.subComments = data?.data
            
            this.subComments.map((ele: any) => {
              if (ele.user && !ele.bgColor) { ele.bgColor = this.mainService.getRandomColor(); }
              if (ele.user_id == this.mainService.getUserDetail.detail?.id) {
                ele.selfComment = true
              } else { ele.selfComment = false }
            })
          },
        }),
      );
    }
    // this.fetchPendingComments();
  }

  public deleteComment(id: string, commentId: string) {
    if (this.flag === 'projects') {
      this.subscription.add(
        this.projectService.httpDeleteComment(id, commentId).subscribe({
          next: ({ data }) => {
            if (data && data.code) {
              this.fetchProjectComments();
              this.showSubComment = false
            }
          },
        }),
      );
    }
    else if (this.flag === 'campaigns') {
      this.subscription.add(
        this.activityService.httpDeleteComment(id, commentId).subscribe({
          next: ({ data }) => {
            if (data && data.code) {
              this.fetchProjectComments();
              this.showSubComment = false
            }
          },
        }),
      );
    }
    else if (this.flag === 'articles') {
      this.subscription.add(
        this.articleService.httpDeleteComment(id, commentId).subscribe({
          next: ({ data }) => {
            if (data && data.code) {
              this.fetchProjectComments();
              this.showSubComment = false
            }
          },
        }),
      );
    }
  }

  public deleteSubComment(id: string, commentId: string, replyId: string) {
    if (this.flag === 'projects') {
      this.subscription.add(
        this.projectService.httpSubDeleteComment(id, commentId, replyId).subscribe({
          next: ({ data }) => {
            if (data && data.code) {
              this.fetchProjectComments();
              this.subCommentSection();
            }
          },
        }),
      );
    }
    else if (this.flag === 'campaigns') {
      this.subscription.add(
        this.activityService.httpSubDeleteComment(id, commentId, replyId).subscribe({
          next: ({ data }) => {
            if (data && data.code) {
              this.fetchProjectComments();
              this.subCommentSection();
            }
          },
        }),
      );
    }
    else if (this.flag === 'articles') {
      this.subscription.add(
        this.articleService.httpSubDeleteComment(id, commentId, replyId).subscribe({
          next: ({ data }) => {
            if (data && data.code) {
              this.fetchProjectComments();
              this.subCommentSection();
            }
          },
        }),
      );
    }
  }

  commentLike(item: any, isLiked: boolean) {
    if (this.isLoggedIn) {
      const body: any = { is_like: isLiked = !isLiked };
      if (this.flag === 'projects') {
        this.subscription.add(
          this.projectService.httpLikeDislikeComment(item?.project_id, item?.id, body).subscribe({
            next: ({ data }) => {
              if (data && data.code) {
                this.fetchProjectComments();
              }
            },
          }),
        );
      }
      else if (this.flag === 'campaigns') {
        
        this.subscription.add(
          this.activityService.httpLikeDislikeComment(item?.campaign_id, item?.id, body).subscribe({
            next: ({ data }) => {
              if (data && data.code) {
                this.fetchProjectComments();
              }
            },
          }),
        );
      }
      else if (this.flag === 'articles') {
        this.subscription.add(
          this.articleService.httpLikeDislikeComment(item?.article_id, item?.id, body).subscribe({
            next: ({ data }) => {
              if (data && data.code) {
                this.fetchProjectComments();
              }
            },
          }),
        );
      }
    } else { this.openLoginModal(); }
  }

  subCommentLike(item: any, isLiked: boolean) {
    if (this.isLoggedIn) {
      const body: any = { is_like: isLiked = !isLiked };
      if (this.flag === 'projects') {
        this.subscription.add(
          this.projectService.httpLikeDislikeSubComment(item?.project_id, item?.parent_id, item?.id, body).subscribe({
            next: ({ data }) => {
              if (data && data.code) {
                this.subCommentSection();
              }
            },
          }),
        );
      }
      else if (this.flag === 'campaigns') {
        this.subscription.add(
          this.activityService.httpLikeDislikeSubComment(item?.campaign_id, item?.parent_id, item?.id, body).subscribe({
            next: ({ data }) => {
              if (data && data.code) {
                this.subCommentSection();
              }
            },
          }),
        );
      }
      else if (this.flag === 'articles') {
        this.subscription.add(
          this.articleService.httpLikeDislikeSubComment(item?.article_id, item?.parent_id, item?.id, body).subscribe({
            next: ({ data }) => {
              if (data && data.code) {
                this.subCommentSection();
              }
            },
          }),
        );
      }
    } else { this.openLoginModal(); }
  }

  clearTextInput() {
    this.commentCtrl.setValue('');
    this.subCommentCtrl.setValue('');
  }

  toggleEmojiPicker() {
    if (!this.container) {
      return;
    }
    this.showEmojiPicker = !this.showEmojiPicker;
    if (this.showEmojiPicker) {
      window.addEventListener("click", this.eventHandler);
    } else {
      window.removeEventListener("click", this.eventHandler);
    }
  }

  eventHandler = (event: Event) => {
    // Watching for outside clicks
    if (!this.container?.nativeElement.contains(event.target as Node)) {
      this.showEmojiPicker = false;
      window.removeEventListener("click", this.eventHandler);
    }
  };

  addEmoji(event: any) {
    var element: any = document.getElementById("meesageArea");
    let pos = element.selectionStart;
    const message: any = this.commentCtrl?.value ? this.commentCtrl?.value : '';
    let text = message?.slice(0, pos)
      + event.emoji.native
      + message?.slice(pos);
    this.commentCtrl.patchValue(text);
    this.showEmojiPicker = !this.showEmojiPicker;
    element.focus();
  }

  onMediaFileSelected(event?: any) {
    event.preventDefault();
    const file = event.target.files[0];

    const sizeKb = Math.round(file.size / 1024);
    if (sizeKb > 5000) {
      this.toasterService.show('Size is greater than 5 mb', 'error');
    } else {
      if (file) {
        this.commentCtrl.setValue(file);

        let formData = new FormData();
        formData.append('comment_images',this.commentCtrl.value)
        formData.append('comment',' ')

        if (this.flag === 'projects') {
        this.subscription.add(
          this.projectService.httpPostComment(this.id, formData).subscribe({
            next: ({ data }) => {
              this.fetchProjectComments();
              this.clearTextInput();
              this.showSubComment = false
            },
          }),
        );
        }
        else if (this.flag === 'campaigns') {
          this.subscription.add(
            this.activityService.httpPostComment(this.id, formData).subscribe({
              next: ({ data }) => {
                this.fetchProjectComments();
                this.clearTextInput();
                this.showSubComment = false
              },
            }),
          );
        }
        else if (this.flag === 'articles') {
          this.subscription.add(
            this.articleService.httpPostComment(this.id, formData).subscribe({
              next: ({ data }) => {
                this.fetchProjectComments();
                this.clearTextInput();
                this.showSubComment = false
              },
            }),
          );
          }
      }
    }
  }



  toggleReplyEmojiPicker() {
    if (!this.replyContainer) {
      return;
    }
    this.showReplyEmojiPicker = !this.showReplyEmojiPicker;
    if (this.showReplyEmojiPicker) {
      window.addEventListener("click", this.eventReplyHandler);
    } else {
      window.removeEventListener("click", this.eventReplyHandler);
    }
  }

  eventReplyHandler = (event: Event) => {
    // Watching for outside clicks
    if (!this.replyContainer?.nativeElement.contains(event.target as Node)) {
      this.showReplyEmojiPicker = false;
      window.removeEventListener("click", this.eventReplyHandler);
    }
  };

  addReplyEmoji(event: any) {
    var element: any = document.getElementById("replyMessageArea");
    let pos = element.selectionStart;
    const message: any = this.subCommentCtrl?.value ? this.subCommentCtrl?.value : '';
    let text = message?.slice(0, pos)
      + event.emoji.native
      + message?.slice(pos);
    this.subCommentCtrl.patchValue(text);
    this.showReplyEmojiPicker = !this.showReplyEmojiPicker;
    element.focus();
  }

  onMediaReplyFileSelected(event: any, itemId: string) {
    event.preventDefault();
    const file = event.target.files[0];

    const sizeKb = Math.round(file.size / 1024);
    if (sizeKb > 5000) {
      this.toasterService.show('Size is greater than 5 mb', 'error');
    } else {
      if (file) {
        this.subCommentCtrl.setValue(file);

        let formData = new FormData();
        formData.append('reply_images',this.subCommentCtrl.value)
        formData.append('comment',' ')
        const subcomment = itemId;
        if (this.flag === 'projects') {
        this.subscription.add(
          this.projectService.httpPostSubComment(this.id,  subcomment, formData).subscribe({
            next: ({ data }) => {
              this.subCommentSection();
              this.clearTextInput();
            },
          }),
        );
        }
        else if (this.flag === 'campaigns') {
          this.subscription.add(
            this.activityService.httpPostSubComment(this.id, subcomment, formData).subscribe({
              next: ({ data }) => {
                this.subCommentSection();
                this.clearTextInput();
              },
            }),
          );
        }
        else if (this.flag === 'articles') {
          this.subscription.add(
            this.articleService.httpPostSubComment(this.id,subcomment, formData).subscribe({
              next: ({ data }) => {
                this.subCommentSection();
                this.clearTextInput();
              },
            }),
          );
          }
      }
    }
  }

  showTranslation(comment: string): void {
    this.commentTranslateService.translateText(comment, 'en')
      .then((translatedText: string) => {
        this.translatedComments.push(translatedText);
      });
  }

  showReplyTranslation(comment: string): void {
    this.commentTranslateService.translateText(comment, 'en')
      .then((translatedText: string) => {
        this.translatedReplyComments.push(translatedText);
      });
  }

  getCountryList() { this.subscription.add(this.coreService.country$.subscribe((res) => { this.countryList = res && res.length ? res : []; })); }

  getCountryName(countryId: string) { return this.countryList.find((ele) => ele.id === countryId)?.name }

  public openCountryViewModal(data: any) {
    let modalRef = this.modalService.open(CountryViewComponent, { backdrop: true, size: 'lg' });
    const obj = { heading: 'Countries'};
    modalRef.componentInstance.patchData(data, this.countryList, obj);
  }

  openImageSlider(index: number){
    let modalRef = this.modalService.open(ImageSliderModalComponent, { backdrop: true, size: 'xl'});
    modalRef.componentInstance.patchData(this.slides, index);
  }

  handleThumbnailClick(index: number): void {
    if(this.slickModal){
      this.slickModal.slickGoTo(index);
      this.selectedThumbnailIndex = index;
    }
  }

  goBack() { window.history.back(); }

  public fetchPendingComments() {
    if (this.isLoggedIn) { 
      if (this.id && this.flag) {
        this.subscription.add(
          this.exploreService.httpPendingComments(this.id, this.flag).subscribe({
            next: ({ data }) => {
              this.pendingComments = data?.count
            },
          }),
        );
      }
    }
  }
  
  ngOnDestroy(): void { this.subscription.unsubscribe(); }

}
