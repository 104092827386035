import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpBackend } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentTranslationService {

  private apiKey = 'AIzaSyAlb5MdW9KeLtZi6sEnaLs7tRmWrfOnO54';
  private apiUrl = 'https://translation.googleapis.com/language/translate/v2';
  private httpClient: HttpClient;

  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  async translateText(text: string, targetLang: string): Promise<string> {
    const params = new HttpParams()
      .set('key', this.apiKey)
      .set('q', text)
      .set('target', targetLang);
  
    try {
      const response = await firstValueFrom(this.httpClient.get<any>(this.apiUrl, { params }));
      // console.log('API Response:', response);
      return response.data.translations[0].translatedText;
    } catch (error) {
      // console.error('Translation failed:', error);
      throw new Error('Something went wrong during translation.');
    }
  }
  
}
