import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonService, HeaderService } from '../../services';
import { environment } from 'src/environment/environment';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
})

export class FooterComponent implements OnInit, OnDestroy {
  public imageUrl: string = environment.imageUrl;
  private subscription: Subscription = new Subscription();
  public footerData: any;
  public isShow: boolean = false;
  public topPosToStartShowing = 100;

  constructor(private commonService: CommonService) { }


  ngOnInit() { this.getFooterData(); }

  @HostListener('window:scroll')
  checkScroll() {

    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) { this.isShow = true; }
    else { this.isShow = false; }
  }

  public getFooterData() {
    this.subscription.add(
      this.commonService.httpGetFooterData().subscribe({
        next: (data: any) => {
          if (data) {
            this.footerData = data.data.footer[0];
          }
        },
      }),
    );
  }
  goToLink(url: string) {
    if (url.includes('http')) { window.open(url, "_blank"); }
    else { window.open("//" + url, "_blank"); }
  }
  // TODO: Cross browsing
  gotoTop() { window.scroll({ top: 0, left: 0, behavior: 'smooth' }); }

  guideTool(){ this.commonService.guideClicked('userGuide'); }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }
}
