export const API_ROUTES = {
  TERMS_PAGES: 'setting/pages',
  FOOTER_PAGE: 'setting/footer',
  AUTH: {
    LOGIN: 'auth/user-login?type=password',
    SIGNUP: 'agg/users/website-signup',
    SSO_LOGIN: 'agg/users/google-sign-in',
    FORGOT_PWD: 'auth/website-forgot-password',
    RESET_PWD: 'auth/reset-password',
    ACTIVATE: 'auth/accounts/active-user',
    CONTACTFORM: 'agg/settings/contactForm'
  },
  COMMON: {
    COUNTRY: 'setting/enum/countries',
    INTEREST: 'setting/enum/interests',
    PROFILE: 'agg/users/get-user-details',
    COUNT: 'agg/users/get-counts',
    UPDATEPROFILE: 'agg/users',
    DELETE_PROFILE: 'agg/users/self',
    UPDATEINTEREST: 'users/profiles',
    UPDATECOURSE: 'users/course',
    CHANGEPASSWORD: 'agg/users',
    RELATED_INTEREST: 'users/profiles/relatedInterest',
    RELATED_INTEREST_DETAIL: 'agg/users',
    GET_INTEREST_DETAIL: 'users/profiles/getInterest',
    REPORTS: 'users/profiles/reports/get-reports',
    POSTREPORT: 'users/profiles/report',
    DashboardData: 'agg/dashboard/homepage',
    DashboardBanner: 'setting/enum/banner/homepage',
    // CAMPAIGNREPORT:'campaigns/:id/report',
    RESTRICTED_CHAT: 'setting/chatSetting'
  },
  CAMPAIGNS: {
    GET: 'campaigns/self',
    POST: 'agg/campaigns',
    GET_BY_ID: 'campaigns',
    ALL: 'campaigns',
    GETALL: 'agg/campaigns'
  },
  PROFILE: {
    UPDATEPROFILE: 'users/profiles/updateProfile',
    LIKE_DISLIKE: 'users/profiles/is_liked_disliked',
    FOLLOW: 'users/profiles/is_followed',
    TOGGLE_POINT_MODAL: 'users/profiles'
  },
  PROJECTS: {
    SELF: 'projects/self',
    POST: 'agg/projects',
    GET: 'projects',
    CONTRIBUTION: 'projects/contribution',
    POST_CONTRIBUTION: 'agg/projects/projectContribution',
    CONTRIBUTIONBYPROJECT: 'agg/projects/contributions',
    CONTRIBUTIONLIKEDISLIKE: 'contribution/is_liked_disliked',
    CONTRIBUTIONAPPROVE: 'agg/projects/projectParticipationApproval',
    PROJECTS_UNDER_CAMPAIGN: 'agg/projects/getProjectsUnderCampaignList',
    SELF_CONTRIBUTIONS: 'projects/get-self-contributions',
  },
  ARTICLES: {
    GET: 'articles',
    GET_All: "articles/self",
    POST: 'agg/articles',
    GET_BY_ID: 'articles',
    GET_COUNTRY: 'setting/enum/countries',
  },
  EXPLORE: {
    CAMPAIGNS: 'agg/campaigns/explore',
    PROJECTS: 'agg/projects/explore',
    ARTICLES: 'agg/articles/explore',
    CAMPAIGN_DETAIL: 'agg/campaigns/getSelf',
    ARTICLE_DETAIL: 'agg/articles/getSelf',
    PROJECT_DETAIL: 'agg/projects/getSelf',
  },
  TOOLBOX: {
    CATEGORY: 'toolbox/categories',
    List: 'toolbox/posts/getCategoryWisePostsWebsite',
    POSTLISTBYID: 'toolbox/posts',
    LIKE_DISLIKE: 'agg/toolbox/posts',
    POSTLIST: 'agg/toolbox/explore',
    RELATED_POST: 'toolbox/posts/relatedPosts',
    POST: 'agg/toolbox'
  },
  NEWS: {
    LIST: 'agg/news/explore',
    GET: 'news',
    NewsView: 'news/store-view',
    POST: 'agg/news',
    GETFILTERS: 'news/category/get-all'
  },
  EVENT: {
    LIST: 'agg/event/explore',
    GET: 'event',
    View: 'event/store-view',
    LIKE: 'agg/event',
  },
  HEADER: {
    BELL: 'agg/notifications/notificationUser',
    DETAIL: 'notifications',
    READ: 'notifications/is_read',
    POINTS: 'agg/users/user-points',
    POINT_READ: 'users/profile-points/toggleReadStatus',
    BADGE_UPDATE: 'agg/users/add-badges/profile',
    RANK: 'users/profile-points/profile-rank',
    BADGE_READ: 'users/profile-badges/toggleReadStatus'
  },
  COMMUNITY: 'community',
  AGGCOMMUNITY: 'agg/community',
  // FOLLOWFOLLOWING: 'agg/users/share-community-list',
  FOLLOWFOLLOWING: 'agg/users/user-listing',
  SHARECOMMUNITY: 'agg/community/send-notifications',
  MESSAGE_REPORT: 'agg/spam_reports/get_chats',
  POST_REPORT: 'spam_reports/chat',
  EXPLORE_COUNT: 'agg/dashboard',
  LEADER_BOARD: {
    BADGE_LIST: 'setting/activity/badges',
    POINT: 'users/profile-points/user-total-points',
    SELF_BADGE_LIST: 'users/profile-badges',
    TOP_USERS: 'agg/users/profile-points/points-listing',
    FOOTER_POINTS: 'agg/dashboard/homepagePoints',
    POINT_LIST: 'setting/activity/points'
  },
  ANNOUNCEMENT: {
    POST: 'setting/announcement',
  },
  USER_ACTIVITY: {
    CAMPAIGNS: 'campaigns/user_campaign',
    PROJECTS: 'projects/user_project',
    ARTICLES: 'articles/user_article',
  },
};
