<ul class="nav nav-pills mb-4 fs-15">
  <li
    *ngFor="let tab of tabsArray"
    class="me-md-3 me-2 mb-2"
    [routerLink]="tab.path"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <a>{{ (tab.title) | translate }}
      <span *ngIf="tab?.count > 0" class="counter border-danger text-danger px-1 fs-12 rounded-1 ms-1 bg-white">{{tab?.count}}</span>
    </a>
  </li>
</ul>
